import React from "react";
import PropTypes from "prop-types";
import "./Button.css";

// Default button used throughout the application.

const Button = props => (
  <div
    className={
      "common-button" +
      ((props.selected ? " selected " : " ") + props.className)
    }
    onClick={props.onClick}
  >
    {props.children}
  </div>
);

Button.propTypes = {
  selected: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

export default Button;
