import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { buildingCommodityEntry } from '../../../common/entryTypes';
import { Checkbox, Select } from '../../../common/Inputs';
import { commodityObject } from '../../../common/initialComponentState';
import * as utils from '../../../common/utilities';
import { initializeBuilding } from '../../../common/initialComponentState';
import check from '../commoditiesTab/images/pi-check.svg';
import error from '../commoditiesTab/images/pi-exclamation.svg';

const CommodityItem = ({entry, building, onChange}) => {
	let tagStatus = entry.key + '.tagStatus',
			active = entry.key + '.active';
	return(
		<div className={"eui-list-option " + entry.class}>
			<div className="eui-status">{getIcon(utils.getNestedValue(building, tagStatus))}</div>
			<Checkbox
				name={active}
				label={entry.label}
				value={utils.getNestedValue(building, active) || utils.getNestedValue(initializeBuilding, active)}
				id="eui"
				onChange={onChange}/>
		</div>
		);
};

const getIcon = (status) => {
	if(status === "")
		return;
	else if(status === "Pi tag and data are good.")
		return (<img src={check} alt={status} />);
	else
		return (<img src={error} alt={status} />);
};

class EuiTab extends React.Component {
	constructor(props) {
		super(props);
		this.buildState = this.buildState.bind(this);
		this.validateAllPiWebIds = this.validateAllPiWebIds.bind(this);
	}

	buildState(field, value, validate) {
		field = field.slice(0, field.indexOf(".active"));
		value = this.buildCommodityValue(field, value, validate);
		value = utils.parseEventObjects(field, value, this.props.building);
		field = field.slice(0, field.indexOf("."));
		this.props.updateCommodity(field, value);
	}

	buildCommodityValue(field, value, validate) {
			let newValue = Object.assign({}, commodityObject);
			newValue.active = value;
		if(value || validate) {
			newValue.tagName = this.buildTagName(field);
			this.getPiWebId(newValue.tagName, field);
		}
		return newValue;
	}



	buildTagName(field) {
		let fieldVariables = field.split("."),
			tag = "";
		if((fieldVariables[3] !== "solar") && (fieldVariables[2] !== "euis")) {
			tag = this.props.building._id + "_" + fieldVariables[3] + "_" + fieldVariables[2];
		}
		else{
			if ((fieldVariables[3] === "solar")){
			tag = "mainstpower." + this.props.building._id + "_" + fieldVariables[2];
		} else if (fieldVariables[2] === "euis") {
			if(fieldVariables[3] === "total")
				tag = this.props.building._id + "_eui";
			else
				tag = this.props.building._id + "_" + fieldVariables[3] + "_eui";
		}
		}
		if (fieldVariables[2] === "demand" || fieldVariables[3] === "solar")
			tag += "_kbtu";
		return tag;
	}

  getPiWebId(tagName, field) {
		let targetProperty = field + ".webId";
		axios.get('/api/pi/tag/' + tagName).then(webId => {
			this.validatePiWebId(webId.data, field);
			field = field.slice(0, field.indexOf("."));
			this.props.updateCommodity(field, utils.parseEventObjects(targetProperty, webId.data, this.props.building));
		}).catch(error => {
			throw (error);
		});
	}

	validateAllPiWebIds() {
		for(let i in buildingCommodityEntry) {
			for(let j=0; j<buildingCommodityEntry[i].length; j++) {
				let commodity = buildingCommodityEntry[i][j],
						active = commodity.key + ".active",
						value = utils.getNestedValue(this.props.building, active);
				this.buildState(active, value, true);
			}
		}
	}

	validatePiWebId(webId, field) {
		let building = this.props.building;
		if (webId === "Invalid tag name.") {
			let invalidValue = Object.assign({}, commodityObject);
			invalidValue.tagStatus = "Pi tag does not exist.";
			invalidValue = utils.parseEventObjects(field, invalidValue, building);
			field = field.slice(0, field.indexOf("."));
			this.props.updateCommodity(field, invalidValue);
		} else {
			let valiatedTimestamp = new Date(),
				timestampProperty = field + ".validatedOn",
				tagStatusProperty = field + ".tagStatus";
			field = field.slice(0, field.indexOf("."));
			this.props.updateCommodity(field, utils.parseEventObjects(timestampProperty, valiatedTimestamp, this.props.building));
			axios.get('/api/pi/webId/' + webId).then(validation => {
				this.props.updateCommodity(field, utils.parseEventObjects(tagStatusProperty, "Pi tag and data are good.", this.props.building));
			}).catch(error => {
				throw (error);
			});
		}
	}

	setEuiStatus(tag) {
		let euis ={
				euiComingSoon: false,
				euiReady: false
			}
		if (tag === "Unavailable") {
			let entries = Object.entries(euis)
			for (let [key, value] of entries ) {
				this.props.updateCommodity(key, value)
			}
		} else {
			euis[tag] = true
			let entries = Object.entries(euis)
			for (let [key, value] of entries ) {
				this.props.updateCommodity(key, value)
		}

	}
}
	placeholder() {
		if (this.props.building.euiReady) {
			return "euiReady"
		}
		else if (this.props.building.euiComingSoon) {
			return "euiComingSoon"
		} else {
			return "Unavailable"
		}
	}



	render() {
		return (
			<div id="eui-content" className="building-tab-content">
				<div className="eui-title">EUIs</div>
        <div>

        </div>
				<div className="eui-list">
					{buildingCommodityEntry['eui'].map((entry, index) => {
						return <CommodityItem key={index} entry={entry} building={this.props.building} onChange={e => this.buildState(e.target.name, e.target.checked)} />
						})
					}
					<div>
						<div className= "eui-dropdown">
							<div>EUI Status:</div>
							<Select options={buildingCommodityEntry.euiReady} onChange= {e => this.setEuiStatus(e.target.value)} value={this.placeholder()}/>
						</div>
					</div>
				</div>
				<div className="eui-validate-button" onClick={this.validateAllPiWebIds}>Validate Pi Tags</div>
			</div>
		);
	}
};

EuiTab.propTypes = {
	building: PropTypes.object.isRequired,
	updateCommodity: PropTypes.func.isRequired
};

export default EuiTab;
