import React from 'react';

export const Checkbox = (props) => {
  let wrapperClass = 'form-group';
  if (props.error && props.error.length > 0) {
    wrapperClass += ' has-error';
  }

  return (
    <div className={wrapperClass}>
		{props.label !== undefined && <label htmlFor={props.name}>{props.label}</label>}
      <div className="field">
        <input
          type="checkbox"
          name={props.name}
          className="form-control"
		  id={props.id}
          onChange={props.onChange}
		  checked={props.value}/>
        {props.error && props.error.length > 0 && <div className="alert alert-danger">{props.error}</div>}
      </div>
			{props.children}
    </div>
  );
};

export const Number = (props) => {
  let wrapperClass = 'form-group';
  if (props.error && props.error.length > 0) {
    wrapperClass += ' has-error';
  }
  return (
    <div className={wrapperClass}>
		{props.label !== undefined && <label htmlFor={props.name}>{props.label}</label>}
      <div className="field">
        <input
          type="number"
          name={props.name}
          className="form-control"
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
		      inputMode="numeric"
          step={props.step}
          min={props.min}
          max={props.max}/>
        {props.error && props.error.length > 0 && <div className="alert alert-danger">{props.error}</div>}
      </div>
			{props.children}
    </div>
  );
};

export const Select = (props) => {
  let wrapperClass = 'form-group';
  if (props.error && props.error.length > 0) {
    wrapperClass += ' has-error';
  }

  return (
    <div className={wrapperClass}>
		{props.label !== undefined && <label htmlFor={props.name}>{props.label}</label>}
      <div className="field">
        <select
		  value={props.value}
		  name={props.name}
          className="form-control"
          onChange={props.onChange}>
	  	    {placeholderData(props.placeholder)}
			{props.options.map((option) => {
            return <option key={option.value} value={option.value}>{option.text}</option>;})
			 }
		  </select>
        {props.error && props.error.length > 0 && <div className="alert alert-danger">{props.error}</div>}
       </div>
			{props.children}
    </div>
  );
};

export const Text = (props) => {
  let wrapperClass = 'form-group';
  if (props.error && props.error.length > 0) {
    wrapperClass += ' has-error';
  }

  return (
    <div className={wrapperClass}>
		{props.label !== undefined && <label htmlFor={props.name}>{props.label}</label>}
      <div className="field">
        <input
          type="text"
          name={props.name}
          className="form-control"
          placeholder={props.placeholder}
          value={props.value} 
          onChange={props.onChange}/>
        {props.error && props.error.length > 0 && <div className="alert alert-danger">{props.error}</div>}
      </div>
			{props.children}
    </div>
  );
};

export const Date = (props) => {
  let wrapperClass = 'form-group';
  if (props.error && props.error.length > 0) {
    wrapperClass += ' has-error';
  }
  return (
    <div className={wrapperClass}>
		{props.label !== undefined && <label htmlFor={props.name}>{props.label}</label>}
      <div className="field">
        <input
          type="month"
          name={props.name}
          className="form-control"
          placeholder={props.placeholder}
          value={props.value?.length > 7 ? props.value.split("T")[0].slice(0,-3) : props.value} 
          onChange={props.onChange}
          min={props.min}
          max={props.max}
          />
        {props.error && props.error.length > 0 && <div className="alert alert-danger">{props.error}</div>}
      </div>
			{props.children}
    </div>
  );
}

const placeholderData = (placeholder) => {
	if(placeholder!=="")
		return <option value="" disabled hidden>{placeholder}</option>;
	return '';
}
