import React from 'react';
import './Help.css';

class Help extends React.Component {
	render() {
		return (
			<a className="help-link" href="https://ucdavis.jira.com/wiki/spaces/AFS/pages/355389001/Campus+Energy+Education+Dashboard+CEED" rel="noopener noreferrer" target="_blank">
				<div className = "help-wrapper" alt="Support documents.">
					?
				</div>
			</a>
		);
	}
}

export default Help;
