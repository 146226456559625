import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function buildingReducer(state = initialState.building, action) {
  switch (action.type) {
	case types.LOAD_BUILDING_SUCCESS:
      return action.building;
		  
    case types.CREATE_BUILDING_SUCCESS:
      return [
        ...state,
        Object.assign({}, action.building)
      ];

    case types.UPDATE_BUILDING_SUCCESS:
      return [
        ...state.filter(building => building.id !== action.building.id),
        Object.assign({}, action.building)
      ];

    default:
      return state;
  }
}
