import React from "react";

/* TODO: Document
 */

export class RootDataType {
  constructor() {
    this.formattedValue = this.formattedValue.bind(this);
    this.parseValue = this.parseValue.bind(this);
    this.reset = this.reset.bind(this);
    this.defaultValue = "";
  }

  static Default = new RootDataType();

  reset() {}

  formattedValue(value) {
    return value;
  }

  parseValue(formattedVal) {
    return formattedVal;
  }

  renderEditor(value, onValueChange, props) {
    return (
      <input
        type="text"
        value={this.formattedValue(value)}
        onChange={e => onValueChange(this.parseValue(e.target.value))}
        {...props}
      />
    );
  }

  renderValue(value) {
    return this.formattedValue(value);
  }
}

export class NumberDataType extends RootDataType {
  constructor(
    decimalPoints = 20,
    numberFormatter = new Intl.NumberFormat("en-US", {
      maximumFractionDigits: decimalPoints
    })
  ) {
    super();
    this.numberFormatter = numberFormatter;
    this.decimalPoints = decimalPoints;
    this.defaultValue = undefined;
    this.addDecimal = "";
  }

  reset() {
    this.addDecimal = "";
  }

  formattedValue(value) {
    let fValue = this.numberFormatter.format(value);
    return fValue === "NaN" ? "" : fValue;
  }

  parseValue(formattedVal, asInt = this.decimalPoints === 0) {
    if (!formattedVal) {
      return undefined;
    }
    let cleanedValue = formattedVal.replace(/[^0-9.]/g, "");
    return asInt ? parseInt(cleanedValue, 10) : parseFloat(cleanedValue);
  }

  renderEditor(value, onValueChange, props) {
    const getDecimal = v =>
      this.decimalPoints ? (v.match(/\.[0-9]*/) || [""])[0] : "";
    let fValue = this.formattedValue(value);
    if (this.addDecimal === "") {
      this.addDecimal = getDecimal(fValue);
    }
    return super.renderEditor(value, onValueChange, {
      ...props,
      onChange: e => {
        // Used to deal with the edge case when the user is trying to add a
        // decimal point.
        this.addDecimal = getDecimal(e.target.value);
        return onValueChange(this.parseValue(e.target.value));
      },
      onFocus: e => {
        this.addDecimal = getDecimal(e.target.value);
      },
      onBlur: e => {
        // Remove the last decimal on blur.
        this.addDecimal = "";
      },
      value:
        value === ""
          ? ""
          : (
              this.formattedValue(this.parseValue(fValue, true)) +
              this.addDecimal
            ).replace(/,/g, "")
    });
  }
}
