export const dataHeader = {
	"buildings": {
		"active": "Active",
		"euiReady": "EUI Ready",
		"euiComingSoon": "EUI Coming Soon",
		"name": "Building Name",
		"_id": "Short Name",
		"buildingType": "Building Type",
		"primaryUse": "Primary Use",
		"primaryPercent": "Primary Percentage",
		"secondaryUse": "Secondary Use",	
		"secondaryPercent": "Seconday Percentage",
		"long": "Longitude",
		"lat": "Latitude",
		"bldgKey": "Building Keys",
		"certifications": "Certifications",
		"alias": "Alias Names",
		"annualUsage.chilledWater": "Annual Usage - Chilled Water",
		"annualUsage.electricity": "Annual Usage - Electricity",
		"annualUsage.gas": "Annual Usage - Natural Gas",
		"annualUsage.solar": "Annual Usage - Solar",
		"annualUsage.steam": "Annual Usage - Steam",
		"monthlyUsage.chilledWater": "Monthly Usage - Chilled Water",
		"monthlyUsage.electricity": "Monthly Usage - Electricity",
		"monthlyUsage.gas": "Monthly Usage - Natural Gas",
		"monthlyUsage.solar": "Monthly Usage - Solar",
		"monthlyUsage.steam": "Monthly Usage - Steam",
		"demand.chilledWater": "Demand - Chilled Water",
		"demand.electricity": "Demand - Electricity",
		"demand.gas": "Demand - Natural Gas",
		"demand.solar": "Demand - Solar",
		"demand.steam": "Demand - Steam"		
	},
	"tips" : {
		"active": "Active",
		"body": "Main Content",
		"headline": "Headline",
		"link": "URL Link",
		"long": "Longitude",
		"lat": "Latitude",
		"visibilityDelay": "Display Start in Seconds"		
	}
};

export const dataTranslation = {
	"buildings": {
		"active": "active",
		"euiReady": "euiReady",
		"euiComingSoon": "euiComingSoon",
		"name": "name",
		"_id": "_id",
		"buildingType": "buildingType",
		"primaryUse": "primaryUse",
		"primaryPercent": "primaryPercent",
		"secondaryUse": "secondaryUse",
		"secondaryPercent": "secondaryPercent",
		"long": "center.long",
		"lat": "center.lat",
		"bldgKey": "bldgKey",
		"certifications": "certifications",
		"alias": "alias",
		"annualUsage.chilledWater": "energyInfo.tags.annualUsage.chilledWater.active",
		"annualUsage.electricity": "energyInfo.tags.annualUsage.electricity.active",
		"annualUsage.gas": "energyInfo.tags.annualUsage.gas.active",
		"annualUsage.solar": "energyInfo.tags.annualUsage.solar.active",
		"annualUsage.steam": "energyInfo.tags.annualUsage.steam.active",
		"monthlyUsage.chilledWater": "energyInfo.tags.monthlyUsage.chilledWater.active",
		"monthlyUsage.electricity": "energyInfo.tags.monthlyUsage.electricity.active",
		"monthlyUsage.gas": "energyInfo.tags.monthlyUsage.gas.active",
		"monthlyUsage.solar": "energyInfo.tags.monthlyUsage.solar.active",
		"monthlyUsage.steam": "energyInfo.tags.monthlyUsage.steam.active",
		"demand.chilledWater": "energyInfo.tags.demand.chilledWater.active",
		"demand.electricity": "energyInfo.tags.demand.electricity.active",
		"demand.gas": "energyInfo.tags.demand.gas.active",
		"demand.solar": "energyInfo.tags.demand.solar.active",
		"demand.steam": "energyInfo.tags.demand.steam.active"
	},
	"tips": {
		"active": "active",
		"body": "body",
		"headline": "headline",
		"link": "link",
		"long": "center.long",
		"lat": "center.lat",
		"visibilityDelay": "visibilityDelay"		
	}
};
