import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as buildingActions from '../../actions/buildingActions';
import PropTypes from 'prop-types';
import { initializeBuilding } from '../common/initialComponentState';
import { parseEventObjects, getNestedValue } from '../common/utilities';
import { buildingCommodityEntry } from '../common/entryTypes';
import { buildingValidation } from '../common/fieldValidation';
import BuildingTabs from './tabs/BuildingTabs.js';
import './BuildingPage.css';

const TYPING_TIMEOUT = 800;
let	timer = null;

class BuildingPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			building: Object.assign({}, initializeBuilding),
			errors: {},
			localErrors: {},
			existingLink: '',
			saving: false
		};
		this.updateBuildingState = this.updateBuildingState.bind(this);
		this.saveBuilding = this.saveBuilding.bind(this);
		this.setNewState = this.setNewState.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		if(this.props.building !== prevProps.building)
			this.setState({
				building: this.props.building
			});
	}

	componentDidMount() {
		this.props.actions.loadBuilding(this.props.match.params.buildingId);
		document.getElementById("information-content").style.display = "block";
	}

	updateBuildingState(event) {
		let field = event.target.name,
				value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		value = event.target.type === 'number' ? parseFloat(value) : value;
		if(field === "_id")
			this.checkDuplicate(value);
		if (field.indexOf(".") > -1) {
			value = parseEventObjects(field, value, this.state.building);
			field = field.slice(0, field.indexOf("."));
		}
		this.setNewState(field, value);
	}

	setNewState(field, value) {
		let building = this.state.building;
		building[field] = value;
		this.setState({
			building
		});
	}

	checkDuplicate(id) {
		clearTimeout(timer);
		timer=setTimeout(()=>{
			let buildings = this.props.buildings,
					localErrors = {};
			for(let i=0; i<buildings.length; i++){
				if(id === buildings[i]._id && id !== this.props.match.params.buildingId){
					localErrors._id = 'This building already exists.';
					localErrors.link = '/building/' + id;
				}
				this.setState({errors: localErrors,
											localErrors: localErrors});
		}
		}, TYPING_TIMEOUT);
	}

	setCommodities() {
		let tempBuilding = Object.assign({}, this.state.building),
				tempArray = [];
		for(let property in buildingCommodityEntry)
			for(let i=0; i<buildingCommodityEntry[property].length; i++) {
				let checkedKey = buildingCommodityEntry[property][i].key;
				if(getNestedValue(tempBuilding, checkedKey) !== undefined && getNestedValue(tempBuilding, checkedKey).active) {
					let commodity = checkedKey.slice( checkedKey.lastIndexOf('.')+1),
							check = true;
					for(let j=0; j<tempArray.length; j++)
						if(tempArray[j] === commodity)
							check = false;
					if(check)
						tempArray.push(commodity);
				}
		}
		tempBuilding.energyInfo.commodities = tempArray;
		this.setState({ building: tempBuilding });
	}

	setUnmeteredCommodities() {
		let tempBuilding = Object.assign({}, this.state.building),
				tempUnmetered = tempBuilding.energyInfo.unmeteredCommodities.sort(),
				tempMetered = tempBuilding.energyInfo.commodities,
				finalUnmetered = [];
		for(let i=0; i<tempUnmetered.length; i++) {
			let check = true;
			for(let j=0; j<tempMetered.length; j++)
				if(tempMetered[j] === tempUnmetered[i])
					check = false;
			if(tempUnmetered[i] === "")
				check = false;
			if(i > 0 && tempUnmetered[i] === tempUnmetered[i-1])
				check = false;
			if(check)
				finalUnmetered.push(tempUnmetered[i]);
		}
		tempBuilding.energyInfo.unmeteredCommodities = finalUnmetered;
		this.setState({ building: tempBuilding });
	}

	async saveBuilding() {
		if(this.buildingFormIsValid()) {
			await this.setCommodities();
			await this.setUnmeteredCommodities();
			this.props.actions.saveBuilding(this.props.match.params.buildingId, this.state.building, this.props.cas.key, this.props.history);
		}
	}

	buildingFormIsValid() {
		let formIsValid = true,
			errors = Object.assign({}, buildingValidation(this.state.building, this.state.localErrors));
		this.setState((prevState) => {
			if(prevState.errors.link !== undefined)
				errors = {...prevState.errors, ...errors};
			return {errors};
		});
		if(Object.keys(errors).length !== 0)
			formIsValid = false;
		return formIsValid;
	}

	render() {
		if(this.state.building.center === undefined)
			return null;
		return ( < div className = "building-wrapper" >
			<div className="building-layout">
			<div className="building-header">
				<div className="building-title"> { this.state.building.name } </div>
				<div className="building-buttons">
					<Link to={`${process.env.PUBLIC_URL}/`} className="building-cancel">Cancel
					</Link>
					<div onClick={this.saveBuilding} className="building-save"> Save </div>
				</div>
			</div>
			<div className="building-error-link">
						{this.state.localErrors.link !== undefined && <Link to={this.state.localErrors.link || ''}>Go to the {this.state.building._id} building?</Link>}
			</div>
			<BuildingTabs building = {this.state.building} onChange = {this.updateBuildingState} errors = {this.state.errors} updateBuilding = {this.setNewState} />
				</div>
				</div>
		);
	}
}

BuildingPage.propTypes = {
	cas: PropTypes.object.isRequired,
	building: PropTypes.object.isRequired,
	buildings: PropTypes.array.isRequired,
	actions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		cas: state.cas,
		building: state.building,
		buildings: state.buildings
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(buildingActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(BuildingPage);
