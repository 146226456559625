import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as casActions from '../../../actions/casActions';
import exitIcon from '../../../images/exit-icon.png';
import './Logout.css';

class Logout extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {
			lastLoggedIn : ''
		};
		this.processLogout = this.processLogout.bind(this);
	}
	
	componentDidMount() {
		if(this.props.cas.lastLogin !== undefined)
			this.setLastLoggedIn();
	}
	
	getTimeFormat(timestamp) {
		let hour = timestamp.getHours(),
				minutes = timestamp.getMinutes(),
				suffix = "AM";
		if(hour === 12)
			suffix = "PM";
		if(hour === 0)
			hour += 12;
		if(hour >= 13) {
			hour -= 12;
			suffix = "PM";
		}
		if(minutes <= 9)
			minutes = "0" + minutes;
		return hour+":"+minutes+" "+suffix;
	}
	
	processLogout() {
		let key = localStorage.getItem("key");
		localStorage.removeItem("key");
		this.props.actions.logoutOfCas(key);
	}
	
	setLastLoggedIn() {
		let lastLoggedIn = "";
		if(this.props.cas.lastLogin === "") 
			lastLoggedIn = "First Login!";
		else {
			let timestamp = new Date(this.props.cas.lastLogin);
			lastLoggedIn += timestamp.getMonth()+1+"/"+timestamp.getDate()+"/"+timestamp.getFullYear() + " ";
			lastLoggedIn += this.getTimeFormat(timestamp);
			this.setState({lastLoggedIn});
		}
	}
	
	render() {
		return (
			<div className = "logout-wrapper" onClick={this.processLogout}>
				<div className = "logout-text">
					<div className = "logout-maintext">	
						Welcome {this.props.cas.name}
					</div>
					<div className="logout-subtext">
						Last logged in: <br />{this.state.lastLoggedIn}
					</div>
				</div>
				<img className="exit-icon" alt="Logout icon" src={exitIcon} />
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		cas: state.cas
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(casActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
