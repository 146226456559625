import {combineReducers} from 'redux';
import cas from './casReducer';
import buildings from './buildingsReducer';
import building from './buildingReducer';
import tips from './tipsReducer';
import tip from './tipReducer';
import records from './airtableReducer';
import CollectionReducerGen from "./collectionReducerGen";
const energyStoryData = CollectionReducerGen("energyStoryData");
const waterDashboard = CollectionReducerGen("waterDashboard");

const rootReducer = combineReducers({
	cas,
	buildings,
	building,
	tips,
	tip,
	energyStoryData,
	waterDashboard,
	records
});

export default rootReducer;
