import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '../../../common/Inputs';

const BUILDING_KEY_PROPERTY = "bldgKey";

const addBuildingKey = (buildingKeys, updateArray) => {
	let newKeys = buildingKeys;
	newKeys.push("");
	updateArray(BUILDING_KEY_PROPERTY, newKeys);
};

const removeBuildingKey = (buildingKeys, index, updateArray) => {
	let newKeys = [];
	for(let i=0; i<buildingKeys.length; i++)
		if(i!==index)
			newKeys.push(buildingKeys[i]);
	updateArray(BUILDING_KEY_PROPERTY, newKeys);	
}

const updateBuildingKeys = (buildingKeys, updateArray) => {
	let buildNewArray = (event, location) => {
		if(event.target !== undefined){
			let newData = event.target.value,
					newArray = buildingKeys;
			newArray[location] = newData;
			updateArray(BUILDING_KEY_PROPERTY, newArray);			
		}
	};
	return(
		<div className="building-tab-building-key-list">
		{buildingKeys.map((buildingKey, index) => {
			return(
				<div className="building-tab-building-key" key={"buildingKey"+index}>
				<div className="building-tab-building-key-remove" onClick={(e) => removeBuildingKey(buildingKeys, index, updateArray)}>
					&times;
				</div>
				<Text
					name="key"
					label="Building Key"
					placeholder="DV-01-00000"
					value={buildingKey}
					onChange={(e) => buildNewArray(e, index)} />
				</div>
				);
			})
		}
		{buildingKeys.length<1 && <div className="no-keys">No Building Keys to display.</div>}		
		</div>
	);
}

const BuildingKeys = ({buildingKeys, errors, updateArray}) => {
	return (
			<div className="building-tab-building-keys">
				<div className="building-tab-building-keys-header">
					<div className="building-tab-building-keys-title">
							Building Keys
						</div>
						<div className="building-tab-building-keys-add" onClick={(e) => addBuildingKey(buildingKeys || [], updateArray)}>
								Add Building Key
						</div>
				</div>
				{updateBuildingKeys(buildingKeys || [], updateArray)}
			</div>
	);
};

BuildingKeys.propTypes = {
	buildingKeys: PropTypes.array.isRequired,
	updateArray: PropTypes.func.isRequired
}

export default BuildingKeys;