import React, { Component } from "react";
import PropTypes from "prop-types";
import ConnectCollection from "../../redux/ConnectCollection";
import Table from "../common/table/Table";
import { ENERGY_STORY_DATA_OPTIONS, ENERGY_STORY_DEFAULT_VALUES} from "./config";
import TabbedNavigation from "../common/tabbedNavigation/TabbedNavigation";
import "./EnergyStory.css";
const TABS_DATA = [
  {
    title: "Energy Use",
    ids: [
      "fy",
      "gasMMBTU",
      "primateGasMMBTU",
      "buildingGasMMBTU",
      "gridMMBTU",
      "carbonNeutralMMBTU",
      "solarFarmMMBTU",
      "renewableMMBTU"
    ]
  },
  {
    title: "Energy Cost",
    ids: ["fy", "gasCost", "electricityCost"]
  },
  {
    title: "Metrics",
    ids: ["fy", "totalMMBTU", "students", "campusSqFt"]
  }
];

class EnergyStory extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {};
    this.props.actions.load();
    this.save = this.save.bind(this);
  }

  save(rawData) {
    let id = rawData._id;
    let data = { ...ENERGY_STORY_DEFAULT_VALUES, ...rawData };
    this.props.actions.save(id, data, this.props.cas.key);
  }

  render() {
    if (this.props.data.length === 0) {
      return null;
    }
    return (
      <div className="energy-story">
        <div className="general-wrapper">
          <div className="general-card">
            <TabbedNavigation
              titles={TABS_DATA.map(d => d.title)}
              renderView={index => (
                <Table
                  data={this.props.data}
                  options={ENERGY_STORY_DATA_OPTIONS.filter(
                    d => TABS_DATA[index].ids.indexOf(d.id) !== -1
                  ).sort(
                    (a, b) =>
                      TABS_DATA[index].ids.indexOf(a.id) -
                      TABS_DATA[index].ids.indexOf(b.id)
                  )}
                  onRowAdd={this.save}
                  onRowEdit={this.save}
                />
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ConnectCollection("energyStoryData")(EnergyStory);
