import React from 'react';

import DropzoneComponent from 'react-dropzone-component';

import 'react-dropzone-component/styles/filepicker.css';
import 'dropzone/dist/dropzone.css';

const componentConfig = {
	iconFiletypes: ['.jpg','.png'],
	showFiletypeIcon: true,
	postUrl: '/api/image/upload'
};

class ImageTab extends React.Component {

	preloadImage(dropzone,buildingId) {
		if(buildingId !== '') {
			fetch(`/api/image/${buildingId}`)
				.then(res => {
					if(res.ok) {
						let file = { url: res.url, name: buildingId };

						dropzone.emit("addedfile", file);
						dropzone.emit("thumbnail", file, res.url);
						dropzone.emit("complete", file);
					}
				})
				.catch(error => console.log(error));
		}	
	}

	eventHandlers(building) {
		let self = this;
		if(this.props.building._id) {
			return {
				init: function (dropzone) {
					dropzone.autoDiscover = false;
					self.preloadImage(dropzone,building._id);
				}
			};
		}
	}

	djsConfig(building) {
		return {
			acceptedFiles: 'image/*',
			addRemoveLinks: true,
			dictDefaultMessage: 'No picture yet for this building. Drop an image file here to upload.',
			maxFilesize: 20,
			maxFiles: 1,
			renameFile: function(file) {
				return building._id;
			},
			thumbnailWidth: 600,
			thumbnailHeight: null,
			thumbnailMethod: 'contain'
		};
	}

	render() {
		if(this.props.building._id) {
			return (
				<div id="image-content" className="building-tab-content">
					<DropzoneComponent eventHandlers={this.eventHandlers(this.props.building)} config={componentConfig} djsConfig={this.djsConfig(this.props.building)}/>
				</div>
			);
		}else return null;
	}
}

export default ImageTab;