import React from 'react';
import PropTypes from 'prop-types';
import { Text, Select } from '../../../common/Inputs';
import { filterTypes } from '../../../common/entryTypes';
import { initializeFilter } from '../../../common/initialComponentState';
import { getNestedValue } from '../../../common/utilities';

class Filter extends React.Component {	
	constructor(props) {
		super(props);
		this.state = {
			filters: [],
			search: ""
		};
		this.textSearch = this.textSearch.bind(this);
		this.addFilter = this.addFilter.bind(this);
	}

	applyFilters(filters, searchText) {
		let	searchBuildings = [],
				list = this.props.buildings;
		//Text search
		if(searchText !== "") {
			let text = searchText.toLowerCase();
			for(let i=0; i<list.length; i++){
				let added = false;
				if(list[i]._id.toLowerCase().indexOf(text) > -1) {
					searchBuildings.push(list[i]);
					added=true;
				}
				else if(list[i].name.toLowerCase().indexOf(text) > -1) {
					searchBuildings.push(list[i]);
					added=true;
				}
				else {
					if(list[i].bldgKey !== undefined && !added)
						for(let j=0; j<list[i].bldgKey.length; j++)
							if(list[i].bldgKey[j].indexOf(text) > -1) {
								searchBuildings.push(list[i]);
								added=true;
								break;
							}
					if(list[i].alias !== undefined && !added)
						for(let k=0; k<list[i].alias.length; k++)
							if(list[i].alias[k].indexOf(text) > -1) {
								searchBuildings.push(list[i]);
								break;
							}
				}
			}
		}
		else
			searchBuildings = list;
		//Applying Filters
		if(filters.length > 0) {
			for(let i=0; i<filters.length; i++) {
				let filteredBuildings = [],
						property = filters[i].property,
						value = filters[i].value;
				if(value !== "") {
					for(let j=0; j<searchBuildings.length; j++){
						if(property.indexOf(".")>-1){
							let commodities = getNestedValue(searchBuildings[j], property);
							for(let k=0; k<commodities.length; k++)
								if(commodities[k] === value)
									filteredBuildings.push(searchBuildings[j]);
						}
						else if(searchBuildings[j][property] !== undefined) {
							if(Array.isArray(searchBuildings[j][property])) {
								let searchArray = searchBuildings[j][property],
										added = false;
								for(let k=0; k<searchArray.length; k++)
									if(searchArray[k].name.toString() === value && !added) {
										filteredBuildings.push(searchBuildings[j]);
										added = true;
									}
							}
							else{
								if(searchBuildings[j][property].toString() === value || value === "")
									filteredBuildings.push(searchBuildings[j]);
							}
					}
				}
				searchBuildings = filteredBuildings;
			}					
		}
		}
		this.props.filterChange(searchBuildings);		
	}
	
	textSearch(event) {
		this.setState({
			search: event.target.value
		});
		this.applyFilters(this.state.filters, event.target.value);
	}
	
	addFilter(event) {
		let filters = this.state.filters;
		filters.push(Object.assign({},initializeFilter));
		this.setState({filters});
	}

	removeFilter(filters, index) {
		let newFilters = [];
		for(let i=0; i<filters.length; i++)
			if(i!==index)
				newFilters.push(filters[i]);
		this.setState({filters: newFilters});		
		this.applyFilters(newFilters, this.state.search);		
	}
	
	updateFilterProperty(event, filters, index) {
		let newValue = event.target.value,
				newFilters = filters;
		newFilters[index].property = newValue;
		newFilters[index].value = "";
		this.setState({filters: newFilters});
		this.applyFilters(newFilters, this.state.search);		
	}
	
	updateFilterValue(event, filters, index) {
		let newValue = event.target.value,
				newFilters = filters;
		newFilters[index].value = newValue;
		this.setState({filters: newFilters});
		this.applyFilters(newFilters, this.state.search);		
	}
		
	loadSelectedPropertyOptions(filter, index) {
		if(filter.property === "")
			return null;
		for(let i=0; i<filterTypes.length; i++)
			if(filterTypes[i].value === filter.property)
				return (
					<React.Fragment>
						<div className="buildings-filter-verb">
							{filterTypes[i].verb}
						</div>
						<Select 
							name="value"
							options={filterTypes[i].options}
							value={filter.value}
							placeholder="Select Filter Value"
							onChange={(e) => this.updateFilterValue(e, this.state.filters, index)}
						/>
					</React.Fragment>
				);
	}
	
	updateFilters() {
		return(
			<div className="buildings-filter-list">
			{this.state.filters.map((filter, index) => {
				return(
					<div className="buildings-filtering" key={"filter"+index}>
					<div className="buildings-filter-remove" onClick={(e) => this.removeFilter(this.state.filters, index)}>
						&times;
					</div>
					<Select 
						name="property"
						label="Filter Type"
						options={filterTypes}
						value={filter.property}
						placeholder="Select Type"
						onChange={(e) => this.updateFilterProperty(e, this.state.filters, index)}
					/>
						{this.loadSelectedPropertyOptions(filter, index)}
					</div>
					);
				})
			}
			</div>
		);		
	}
	
  render() {
    return (
      <div className="buildings-filters">
			<div className="buildings-filter-add" onClick={this.addFilter}>
				Add Filter
			</div>
				<Text 
					name=""
					placeholder="Search"
					value={this.props.search}
					onChange={this.textSearch}
				/>
				{this.updateFilters()}
      </div>
    );
  }
}

Filter.propTypes = {
	buildings: PropTypes.array.isRequired,
	filterChange: PropTypes.func.isRequired
};

export default Filter;