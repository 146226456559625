export const initializeBuilding = {
	"_id": "",
	"active": false,
	"alias": [],
	"bldgKey": [],
	"buildingType": "",
	"center": {
		"long": 0,
		"lat": 0
	},
	"certifications": [],
	"energyInfo": {
		"commodities": [],
		"tags": {
			"annualCost": {
				"active": false,
				"webId": "",
				"tagName": "",
				"validatedOn": "",
				"tagStatus": ""
			},
			"annualUsage": {
				"chilledWater": {
					"active": false,
					"webId": "",
					"tagName": "",
					"validatedOn": "",
					"tagStatus": ""
				},
				"electricity": {
					"active": false,
					"webId": "",
					"tagName": "",
					"validatedOn": "",
					"tagStatus": ""
				},
				"gas": {
					"active": false,
					"webId": "",
					"tagName": "",
					"validatedOn": "",
					"tagStatus": ""
				},
				"solar": {
					"active": false,
					"webId": "",
					"tagName": "",
					"validatedOn": "",
					"tagStatus": ""
				},
				"steam": {
					"active": false,
					"webId": "",
					"tagName": "",
					"validatedOn": "",
					"tagStatus": ""
				}
			},
			"demand": {
				"chilledWater": {
					"active": false,
					"webId": "",
					"tagName": "",
					"validatedOn": "",
					"tagStatus": ""
				},
				"electricity": {
					"active": false,
					"webId": "",
					"tagName": "",
					"validatedOn": "",
					"tagStatus": ""
				},
				"gas": {
					"active": false,
					"webId": "",
					"tagName": "",
					"validatedOn": "",
					"tagStatus": ""
				},
				"solar": {
					"active": false,
					"webId": "",
					"tagName": "",
					"validatedOn": "",
					"tagStatus": ""
				},
				"steam": {
					"active": false,
					"webId": "",
					"tagName": "",
					"validatedOn": "",
					"tagStatus": ""
				}
			},
			"euis": {
				"chilledWater": {
					"active": false,
					"webId": "",
					"tagName": "",
					"validatedOn": "",
					"tagStatus": ""
				},
				"electricity": {
					"active": false,
					"webId": "",
					"tagName": "",
					"validatedOn": "",
					"tagStatus": ""
				},
				"gas": {
					"active": false,
					"webId": "",
					"tagName": "",
					"validatedOn": "",
					"tagStatus": ""
				},
				"solar": {
					"active": false,
					"webId": "",
					"tagName": "",
					"validatedOn": "",
					"tagStatus": ""
				},
				"steam": {
					"active": false,
					"webId": "",
					"tagName": "",
					"validatedOn": "",
					"tagStatus": ""
				}
			},
			"monthlyUsage": {
				"chilledWater": {
					"active": false,
					"webId": "",
					"tagName": "",
					"validatedOn": "",
					"tagStatus": ""
				},
				"electricity": {
					"active": false,
					"webId": "",
					"tagName": "",
					"validatedOn": "",
					"tagStatus": ""
				},
				"gas": {
					"active": false,
					"webId": "",
					"tagName": "",
					"validatedOn": "",
					"tagStatus": ""
				},
				"solar": {
					"active": false,
					"webId": "",
					"tagName": "",
					"validatedOn": "",
					"tagStatus": ""
				},
				"steam": {
					"active": false,
					"webId": "",
					"tagName": "",
					"validatedOn": "",
					"tagStatus": ""
				}
			}
		},
		"unmeteredCommodities": []
	},
	"euiReady": false,
	"euiComingSoon": false,
	"leedCertified": false,
	"name": "",
	"primaryPercent": 0,
	"primaryUse": "",
	"secondaryPercent": 0,
	"secondaryUse": ""
};

export const initializeCertification = {
	"name": '',
	"url": '',
	"type": ''
};

export const initializeFilter = {
	"property": '',
	"value": ''
};

export const commodityObject = {
	"active": false,
	"webId": "",
	"tagName": "",
	"validatedOn": "",
	"tagStatus": ""
};

export const initializeJoulesTip = {
	"_id": "",
	"active": false,
	"body": "",
	"headline": "",
	"imageLocation": "",
	"link": "",
	"center": {
		"long": 0,
		"lat": 0
	},
	"visibilityDelay": 0
};

export const campusEdges = {
	"minimumLatitude" : 38.5,
	"maximumLatitude" : 38.6,
	"minimumLongitude" : -122,
	"maximumLongitude" : -121
};

export const setObjectDefaults = function (defaultObject, targetObject) {
	for (var property in targetObject) {
		try {
			if (targetObject[property].constructor === Object)
				defaultObject[property] = setObjectDefaults(defaultObject[property], targetObject[property]);
			else
				defaultObject[property] = targetObject[property];
		} catch (error) {
			defaultObject[property] = targetObject[property];
		}
	}
	return defaultObject;
};
