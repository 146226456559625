import React from 'react';
import PropTypes from 'prop-types';
import * as Inputs from '../../../common/Inputs';
import { certificationTypes, leedTypes } from '../../../common/entryTypes';
import { initializeCertification } from '../../../common/initialComponentState';

const CERTIFICATION_PROPERTY = "certifications";

const addCertification = (certs, updateArray) => {
	let newCerts = certs;
	newCerts.push(Object.assign({}, initializeCertification));
	updateArray(CERTIFICATION_PROPERTY, newCerts);
};

const removeCertification = (certs, index, updateArray) => {
	let newCerts = [];
	for(let i=0; i<certs.length; i++)
		if(i!==index)
			newCerts.push(certs[i]);
	updateArray(CERTIFICATION_PROPERTY, newCerts);	
}

const updateCertifications = (certs, updateArray) => {
	let buildNewArray = (event, location) => {
		if(event.target !== undefined){
			let newData = Object.assign({}, certs[location]),
					field = event.target.name,
					value = event.target.value,
					newArray = certs;
			newData[field] = value;
			if(value === "breeam")
				newData.type = "";
			newArray[location] = newData;
			updateArray(CERTIFICATION_PROPERTY, newArray);			
		}
	};
	return(
		<div className="building-tab-certification-list">
		{certs.map((certification, index) => {
			return(
				<div className="building-tab-certification" key={"certification"+index}>
				<div className="building-tab-certifications-remove" onClick={(e) => removeCertification(certs, index, updateArray)}>
					&times;
				</div>
				<Inputs.Select 
					name="name"
					label="Certification Type"
					options={certificationTypes}
					value={certification.name}
					placeholder="Select Type"
					onChange={(e) => buildNewArray(e, index)}
				/>
				{loadCertificationOptions(certification, index, buildNewArray)}
			 	</div>
				);
			})
		}
		{certs.length<1 && <div className="no-certs">No Certifications to display.</div>}
		</div>
	);
}

const loadCertificationOptions = (selection, index, buildNewArray) => {
	if(selection.name===undefined)
		return null;
	if(selection.name==="leed")
		return(
			<React.Fragment>
				<Inputs.Select 
					name="type"
					label="LEED Rating"
					options={leedTypes}
					value={selection.type}
					placeholder='Certified'
					onChange={(e) => buildNewArray(e, index)}
					/>
				<div className="full-text-input">
				<Inputs.Text
					name="url"
					label="URL"
					placeholder="https://www.example.com/"
					value={selection.url}
					onChange={(e) => buildNewArray(e, index)} />
				</div>
			</React.Fragment>
		);
	if(selection.name==="breeam")
		return(
				<div className="full-text-input">
				<Inputs.Text
					name="url"
					label="URL"
					placeholder="https://www.example.com/"
					value={selection.url}
					onChange={(e) => buildNewArray(e, index)}
					/>
				</div>
		);	
}

const Certifications = ({certifications, errors, updateArray}) => {
	return (
			<div className="building-tab-certifications">
				<div className="building-tab-certifications-header">
					<div className="building-tab-certifications-title">
							Certifications
						</div>
						<div className="building-tab-certifications-add" onClick={(e) => addCertification(certifications || [], updateArray)}>
								Add Certification
						</div>
				</div>
				{updateCertifications(certifications || [], updateArray)}
			</div>
	);
};

Certifications.propTypes = {
	certifications: PropTypes.array.isRequired,
	updateArray: PropTypes.func.isRequired
}

export default Certifications;