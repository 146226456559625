import React from 'react';
import {Link} from 'react-router-dom';
import checkMark from '../../../images/check.svg';
import chilledWater from './images/chilledWater.svg';
import electricity from './images/electricity.svg';
import gas from './images/gas.svg';
import solar from './images/solar.svg';
import steam from './images/steam.svg';

const capitalizeFirstLetter = (stringValue) => {
	if(stringValue !== undefined)
		return stringValue.charAt(0).toUpperCase() + stringValue.slice(1);
}

const convertBooleanToImage = (boolValue) => {
	if(boolValue)
		return <img src={checkMark} alt="True." />;
	return "";
};

const insertCommoditiesIcons = (item, buildingId) => {
	if(item.commodity === "chilledWater")
		return <img key={buildingId+item.commodity} src={chilledWater} alt="Chilled Water" />;
	if(item.commodity === "electricity")
		return <img key={buildingId+item.commodity} src={electricity} alt="Electricity" />;
	if(item.commodity === "gas")
		return <img key={buildingId+item.commodity} src={gas} alt="Natural Gas" />;
	if(item.commodity === "solar")
		return <img key={buildingId+item.commodity} src={solar} alt="Rooftop Solar" />;
	if(item.commodity === "steam")
		return <img key={buildingId+item.commodity} src={steam} alt="Steam" />;
}

const BuildingListRow = ({building}) => {
	let buildingLink = process.env.PUBLIC_URL + "/building/"+building._id;
  return (
	  <Link to={buildingLink}>
		<div className="buildings-table-row">
		  <div className="buildings-display-name">{building.name}</div>
		  <div className="buildings-short-name">{building._id}</div>
			  <div className="buildings-commodities-images buildings-commodities">{(building.energyInfo!==undefined) ? building.energyInfo.commodities.map((commodity)=>{return insertCommoditiesIcons({commodity}, building._id)}) : " "}</div>
		  <div className="buildings-eui-ready buildings-boolean-image">{convertBooleanToImage(building.euiReady)}</div>
		  <div className="buildings-type">{capitalizeFirstLetter(building.buildingType)}</div>
		  <div className="buildings-active buildings-boolean-image">{convertBooleanToImage(building.active)}</div>
			<div className="buildings-hot-water buildings-boolean-image">{convertBooleanToImage(building.energyInfo.hotWaterConversion)}</div>
		</div>
	  </Link>
  );
};

export default BuildingListRow;
