import axios from "axios";
import { COLLECTION_LOAD, COLLECTION_SAVE } from "./actionTypes";

/*
  Generates load & save actions for a collections.
  EXPECTED API Paths:
    load path: ./api/[COLLECTION_NAME]/[OPTIONAL ID]
    save path: ./api/[COLLECTION_NAME]/[ID]/[KEY]
*/
export default collectionName => {
  let LOAD = COLLECTION_LOAD(collectionName);
  let SAVE = COLLECTION_SAVE(collectionName);
  return {
    loadSuccess(dataList) {
      return {
        type: LOAD,
        status: "success",
        data: dataList
      };
    },
    saveSuccess(dataList) {
      return {
        type: SAVE,
        status: "success",
        data: dataList
      };
    },

    load(id = null) {
      return dispatch => {
        axios
          .get(
            `${process.env.PUBLIC_URL}/api/${collectionName}/${id ? id : ""}`
          )
          .then(response => {
            dispatch(this.loadSuccess(response.data));
          })
          .catch(error => {
            throw(error);
          });
      };
    },

    save(id = "new", data, key) {
      return dispatch => {
        axios
          .post(
            `${process.env.PUBLIC_URL}/api/${collectionName}/${id}/${key}`,
            data
          )
          .then(response => {
            response.status === 200 &&
              dispatch(this.saveSuccess(response.data));
          })
          .catch(error => {
            throw(error);
          });
      };
    }
  };
};
