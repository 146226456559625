import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function casReducer(state = initialState.cas, action) {
  switch (action.type) {
	case types.CAS_LOGIN_SUCCESS:
      return action.cas;
		  
    case types.CAS_LOGOUT_SUCCESS:
      return initialState.cas;

		default:
      return state;
  }
}