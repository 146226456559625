import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CSVLink } from "react-csv";
import * as utils from '../common/utilities';

import './GeneralPage.css';

class GeneralPage extends React.Component {
	
	setDownloadButton(buttonType) {
		if(this.props[buttonType].length > 0)
			return (
				<CSVLink
					data={utils.getCsvData(buttonType, this.props.buildings)}
					filename={utils.getCsvFilename(buttonType)}
					headers={utils.getCsvHeaders(buttonType)}
				>
					<div className={this.setDownloadButtonClass(buttonType)}>
						Download {utils.capitalizeFirstLetter(buttonType)}
					</div>
				</CSVLink>
			);
		return (
			<div className={this.setDownloadButtonClass(buttonType)}>
				Download {utils.capitalizeFirstLetter(buttonType)}
			</div>			
		);	
	}
	
	setDownloadButtonClass(buttonType) {
		let className = "general-button-download-csv";
		if(this.props[buttonType].length > 0)
			className += " active";
		return className;
	}
	
  render() {
    return (
      <div className="general-wrapper">
				<div className="general-card">
					<div className="general-download-buttons">
						{this.setDownloadButton("buildings")}
						{this.setDownloadButton("tips")}
					</div>
					<div className="general-quick-start">
						<h2>
							Getting Started
						</h2>
						<div className="general-header">
							Adding a building
						</div>
						<div className="general-body">
							<p>To begin, click the Buildings navigation header to load the buildings list. On the top right of the list, you'll find a button for Add Building. Fill out the appropriate fields. If you don't have all the information initially, you can leave the Active box unchecked. This will allow you to save your progress with a reduced number of required fields.</p> 
							<p>The information tab includes standard building information to get the location added to CEED. The commodities tab will allow you to load individual building graphs with data from Pi.</p>
							<p>After you've filled out the information, use the Save button on the top right of the form. If the building has been set to active, and includes the necessary information, it will appear on CEED instantly. You may need to refresh CEED to see the results.</p>
						</div>
						<div className="general-header">
							Adding a Joules Tip
						</div>
						<div className="general-body">
							<p>To begin, click the Joules Tips navigation header to load the tips list. On the top right of the list, you'll find a button for Add Joules Tips. Fill out the appropriate fields. If you don't have all the information initially, you can leave the Active box unchecked. This will allow you to save your progress with a reduced number of required fields.</p> 
							<p>The Headline field will be the largest text area at the top of the Joules map hover. The main content will be the body of the messaging. The URL Link field will be the location that the user will be redirected if they click on the map hover content. Display Start in Seconds will be how long the map will be visible before this Joules tip appears on the map.</p>
							<p>At this time, Joules Tips have not been added as a feature to CEED.</p>
						</div>
					</div>
					<div className="general-faq">
						<h2>
							Frequently Asked Questions
						</h2>
						<div className="general-header">
							How do the EUI options work?
						</div>
						<div className="general-body">
							<p>There are two EUI options on an individual building page, and an implicit third option of Incomplete. Checking EUI Ready will make the EUI value and its bubble appear on the main map area of CEED. Checking EUI Coming Soon will display the appropriate messaging on the building popup when it is clicked. Leaving both unchecked will display Incomplete Energy Use Intensity on the building popup. The building can't be saved if both options are selected.</p>
						</div>
						<div className="general-header">
							What are Alias Names?
						</div>
						<div className="general-body">
							<p>Alias Names are the alternative names that buildings may be known as on campus. For example, the Activities and Recreation Center is more commonly known as "The ARC". This functionality affects the quality of results when searching the map for a particular building. Multiple aliases can be added to a building.</p>
						</div>
						<div className="general-header">
							Why can a building have multiple building keys?
						</div>
						<div className="general-body">
							<p>Some buildings on campus may consist of multiple structures. For example, Tercero 3 is a cluster of housing buildings. Having accurate building keys is important to CEED as the square footage values are determined through this mechanism. This affects many calculations within the app.</p>
						</div>
					</div>
				</div>
      </div>
    );
  }
}

GeneralPage.propTypes = {
	buildings: PropTypes.array.isRequired,
	tips: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  return {
    buildings: state.buildings,
		tips: state.tips
  };
}

export default connect(mapStateToProps)(GeneralPage);