import * as DataTypes from "../common/dataTypes";

const DATA_OPTIONS = {
  fy: {
    title: "Fiscal Year",
    dataType: new DataTypes.RootDataType(),
    valid: value => /[0-9]{4}-[0-9]{2}/.test(value)
  },
  students: {
    title: "Enrolled Students",
    dataType: new DataTypes.NumberDataType(0),
    valid: v => v === undefined || Number.isInteger(v)
  },
  totalMMBTU: {
    title: "Total Energy Use"
  },
  campusSqFt: {
    title: "Square Footage"
  },
  gasMMBTU: {
    title: "Heating Plant Natural Gas"
  },
  primateGasMMBTU: {
    title: "Primate Heating Plant Natural Gas"
  },
  buildingGasMMBTU: {
    title: "Building Natural Gas"
  },
  gridMMBTU: {
    title: "Carbon-Based Electricity"
  },
  carbonNeutralMMBTU: {
    title: "Hydropower"
  },
  solarFarmMMBTU: {
    title: "Solar Farm Electricity"
  },
  renewableMMBTU: {
    title: "Other Onsite Renewables"
  },
  gasCost: {
    title: "Natural Gas"
  },
  electricityCost: {
    title: "Electricity"
  },

  //OTHER UNUSED ID's
  carbonNeutralKWH: {},
  gridKWH: {},
  cogenKWH: {},
  cogenMMBTU: {},
  renewableKWH: {},
  gasTHERMS: {},
  energyPerSqFt: {}
};

// Collection Configuration
export const ENERGY_STORY_DATA_OPTIONS = (_ => {
  let results = [];
  // Set default values
  for (let key in DATA_OPTIONS) {
    results.push({
      ...{
        id: key,
        title: key,
        dataType: new DataTypes.NumberDataType(),
        valid: v => v === undefined || Number.isFinite(v)
      },
      ...DATA_OPTIONS[key]
    });
  }

  return results;
})();

export const ENERGY_STORY_DEFAULT_VALUES = (_ => {
  let results = {};
  ENERGY_STORY_DATA_OPTIONS.forEach(option => {
    results[option.id] = option.dataType.defaultValue;
  });
  return results;
})();
