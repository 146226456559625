import * as DataTypes from "../common/dataTypes";

const DATA_OPTIONS = {
  year: {
    title: "Year",
    dataType: new DataTypes.RootDataType(),
    valid: value => /[0-9]{4}/.test(value)
  },
  month: {
    title: "Month",
    dataType: new DataTypes.RootDataType(),
    valid: value => /[a-zA-Z]{3}/.test(value)
  },
  domestic: {
    title: "Domestic"
  },
  utility: {
    title: "Utility"
  },
  fisheries: {
    title: "Fisheries"
  },
  agriculture: {
    title: "Agriculture"
  },
  normalizedDomesticWaterUsage: {
    title: "Domestic Water"
  },
  normalizedUtilityWaterUsage: {
    title: "Utility Water"
  },
};

// Collection Configuration
export const WATER_DATA_OPTIONS = (_ => {
  let results = [];
  // Set default values
  for (let key in DATA_OPTIONS) {
    results.push({
      ...{
        id: key,
        title: key,
        dataType: new DataTypes.NumberDataType(),
        valid: v => v === undefined || Number.isFinite(v)
      },
      ...DATA_OPTIONS[key]
    });
  }

  return results;
})();

export const WATER_DEFAULT_VALUES = (_ => {
  let results = {};
  WATER_DATA_OPTIONS.forEach(option => {
    results[option.id] = option.dataType.defaultValue;
  });
  return results;
})();
