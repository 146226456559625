export const CAS_LOGIN_SUCCESS = 'CAS_LOGIN_SUCCESS';
export const CAS_LOGOUT_SUCCESS = 'CAS_LOGOUT_SUCCESS';
export const LOAD_BUILDINGS_LIST_SUCCESS = 'LOAD_BUILDINGS_LIST_SUCCESS';
export const LOAD_BUILDING_SUCCESS = 'LOAD_BUILDING_SUCCESS';
export const CREATE_BUILDING_SUCCESS = 'CREATE_BUILDING_SUCCESS';
export const UPDATE_BUILDING_SUCCESS = 'UPDATE_BUILDING_SUCCESS';
export const LOAD_TIPS_LIST_SUCCESS = 'LOAD_TIPS_LIST_SUCCESS';
export const LOAD_TIP_SUCCESS = 'LOAD_TIP_SUCCESS';
export const CREATE_TIP_SUCCESS = 'CREATE_TIP_SUCCESS';
export const UPDATE_TIP_SUCCESS = 'UPDATE_TIP_SUCCESS';
export const COLLECTION_LOAD = collectionName => "load_" + collectionName;
export const COLLECTION_SAVE = collectionName => "save_" + collectionName;
export const LOAD_AIRTABLE_SUCCESS = 'LOAD_AIRTABLE_SUCCESS';
