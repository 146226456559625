import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './BuildingListPage.css';
import Filter from './filter/Filter';
import BuildingListRow from './BuildingListRow';

class BuildingListPage extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			buildings: this.props.buildings,
			filters: [],
			filteredBuildings: this.props.buildings,
			search: ""
		};
		this.updateFilteredBuildings = this.updateFilteredBuildings.bind(this);
	}
	
	componentDidUpdate(prevProps) {
		if(JSON.stringify(prevProps.buildings) !== JSON.stringify(this.props.buildings)){
			this.setState({
				buildings: this.props.buildings,
				filteredBuildings: this.props.buildings
			});
		}
	}
	
	updateFilteredBuildings(filteredBuildings) {
		this.setState({filteredBuildings: filteredBuildings});
	}
		
	render() {
    return (
      <div className="buildings-wrapper">
			<div className="buildings-table">
				<div className="buildings-top-row">
					<Filter buildings={this.state.buildings} filterChange={this.updateFilteredBuildings} />
					<Link to={`${process.env.PUBLIC_URL}/building/new`} className="new-building-button">
						<div className="create-new-building">Add Building</div>
					</Link>
				</div>
				<div className="buildings-header">
						<div className="buildings-display-name">Display Name</div>
						<div className="buildings-short-name">Short Name</div>
						<div className="buildings-commodities">Commodities</div>
						<div className="buildings-eui-ready">EUI Ready</div>
						<div className="buildings-type">Building Type</div>
						<div className="buildings-active">Active</div>
						<div className="buildings-active">Hot Water</div>
				</div>
				<div className="buildings-body">
					{this.state.filteredBuildings.map((building) =>
						<BuildingListRow key={building._id} building={building}/>
					)}
					{this.state.filteredBuildings.length === 0 && <div className="buildings-none">No results found.</div>}
				</div>
			</div>
      </div>
    );
  }
}

BuildingListPage.propTypes = {
	buildings: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  return {
    buildings: state.buildings
  };
}

export default connect(mapStateToProps)(BuildingListPage);