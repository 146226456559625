import * as types from './actionTypes';
import axios from 'axios';
import { initializeBuilding, setObjectDefaults } from '../components/common/initialComponentState';

export function loadBuildingsListSuccess(buildings) {
  return { type: types.LOAD_BUILDINGS_LIST_SUCCESS, buildings};
}

export function loadBuildingSuccess(building) {
  return { type: types.LOAD_BUILDING_SUCCESS, building};
}

export function createBuildingSuccess(building) {
  return {type: types.CREATE_BUILDING_SUCCESS, building};
}

export function updateBuildingSuccess(updateResponse) {
  return {type: types.UPDATE_BUILDING_SUCCESS, updateResponse};
}

export function loadBuildingsList() {
  return function(dispatch) {
    axios.get(process.env.PUBLIC_URL + '/api/building').then(buildings => {
      dispatch(loadBuildingsListSuccess(buildings.data));
    }).catch(error => {
      throw(error);
    });
  };
}

export function loadBuilding(buildingId) {
  return function(dispatch) {
	  if(buildingId === "new")
		  dispatch(loadBuildingSuccess(initializeBuilding));
	  else{
		let buildingUrl = process.env.PUBLIC_URL + '/api/building/' + buildingId;
		axios.get(buildingUrl).then(building => {
		  dispatch(loadBuildingSuccess(setObjectDefaults(initializeBuilding, building.data[0])));
		}).catch(error => {
		  throw(error);
		});
	  }
  };
}


export function saveBuilding(buildingId, buildingState, key, history) {
  return function (dispatch, getState) {
	  let buildingUrl = process.env.PUBLIC_URL + '/api/building/' + buildingId + '/' + key;
	  axios.post(buildingUrl, buildingState)
		  .then(response => {
		  if(response.status === 200)
			  history.push("/");
	  }).catch(error => {
      throw(error);
    });
  };
}
