import * as downloadUtils from './downloadHeaders';

// Capitalizes the first letter of the string passed in.
export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

//Utility for gathering a value nested in an object.
// I.E. 'center.long'
export const getNestedValue = (building, key) => {
  if(building === undefined || key === undefined)
		return "";
	if(key.indexOf(".") === -1)
		return building[key];
	let layer = key.slice(0, key.indexOf(".")),
			newKey = key.slice(key.indexOf(".")+1, key.length);
	return getNestedValue(building[layer], newKey);
};

export const buildUri = string => {
	return window.location.protocol + '//' + window.location.host + string;
};

export const parseEventObjects = (target, newValue, tempBuilding) => {
	let temp = target.slice(0, target.indexOf(".")),
		tempObj = tempBuilding;
	if (target.indexOf(".") !== -1) {
		tempObj = tempBuilding[temp];
		target = (target.slice(target.indexOf(".") + 1, target.length));
		parseEventObjects(target, newValue, tempObj);
	} else
		tempObj[target] = newValue;
	return tempObj;
};

//     ____ ______     __  _   _ _   _ _ _ _   _
//    / ___/ ___\ \   / / | | | | |_(_) (_) |_(_) ___  ___
//   | |   \___ \\ \ / /  | | | | __| | | | __| |/ _ \/ __|
//   | |___ ___) |\ V /   | |_| | |_| | | | |_| |  __/\__ \
//    \____|____/  \_/     \___/ \__|_|_|_|\__|_|\___||___/
//
export const getCsvFilename = downloadType => {
  let downloadDate = new Date(),
    downloadMonth = downloadDate.getMonth() + 1;
  return (
    downloadType +
    "_" +
    downloadMonth +
    "-" +
    downloadDate.getDate() +
    "-" +
    downloadDate.getFullYear() +
    ".csv"
  );
};

export const getCsvHeaders = downloadType => {
	let source = Object.assign({}, downloadUtils.dataHeader[downloadType]),
			headers = [];
	for(let key in source)
		headers.push({'label': source[key], 'key': key})
	return headers;
};

export const getCsvData = (downloadType, dataArray) => {
	let formattedData = [];
	for(let i=0; i<dataArray.length; i++) {
		let dataObject = Object.assign({}, downloadUtils.dataTranslation[downloadType]);
		for(let key in dataObject)
			dataObject[key] = parseData(getNestedValue(dataArray[i], dataObject[key]));
		formattedData.push(dataObject);
	}
	return formattedData;
};

const parseData = data => {
	if(Array.isArray(data)) {
		let newData = "";
		for(let i=0; i<data.length; i++){
			if(typeof data[i] === "object") {
				newData += data[i].name + " - ";
				newData += data[i].url + "; ";
			}
			else
				newData += data[i] + "; ";
		}
		return newData;
	}
	else if(data === false)
		return "FALSE";
	return data;
}
