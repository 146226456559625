import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import collectionActionsGen from "../actions/collectionActionsGen";

/* TODO: All mongo collection generators should be put into a packaged with a
 * set of well documented apis.
 */

const ConnectCollection = collectionName => {
  let mapStateToProps = state => ({
    data: state[collectionName],
    cas: state.cas
  });

  let mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(collectionActionsGen(collectionName), dispatch)
  });

 return connect(mapStateToProps, mapDispatchToProps);
};

export default ConnectCollection;
