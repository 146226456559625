import React, { Component } from "react";
import PropTypes from "prop-types";
import ConnectCollection from "../../redux/ConnectCollection";
import Table from "../common/table/Table";
import { WATER_DATA_OPTIONS} from "./config";
import TabbedNavigation from "../common/tabbedNavigation/TabbedNavigation";
import "./Water.css";
const TABS_DATA = [
  {
    title: "Goal",
    ids: [
      "year",
      "normalizedDomesticWaterUsage",
      "normalizedUtilityWaterUsage"
    ]
  }
  ,
  {
    title: "Usage",
    ids: [
      "year",
      "month",
      "domestic",
      "utility",
      "agriculture",
      "fisheries"
    ]
  },
];

class Water extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      goalTabData:[],
      usageTabData: [],
    };
    this.props.actions.load();
    this.saveGoal = this.saveGoal.bind(this);
    this.saveUsage = this.saveUsage.bind(this);
    this.save = this.save.bind(this);
  }

  // Helper function for rebuilding the data
  createEmpObj() {
    let emptyObj = {
      _id: 'new',
      year: '',
      monthlyUsage: {},
      usage: {}
    }
    return emptyObj;
  }

  rebuildGoal(obj, objYear) {
    let saveData = {};
    let rowFound = false;
    this.props.data.forEach((item) => {
      if(item.year === objYear) {
        saveData = item;
        rowFound = true;
        Object.assign(saveData.usage, obj);
      }
    });
    if (!rowFound) {
      saveData = this.createEmpObj();
      Object.assign(saveData.usage, obj);
      saveData.year = objYear;
    }
    return saveData;
  }

  rebuildUsage(obj, objYear, objMonth) {
    let saveData = {};
    let rowFound = false;
    this.props.data.forEach((item) => {
      if(item.year === objYear) {
        saveData = item;
        rowFound = true;
        if (saveData.monthlyUsage[objMonth] == null) {
          saveData.monthlyUsage[objMonth] = {};
        }
        Object.assign(saveData.monthlyUsage[objMonth], obj);
      }
    });

    if (!rowFound) {
      saveData = this.createEmpObj();
      saveData.monthlyUsage[objMonth] = {};
      Object.assign(saveData.monthlyUsage[objMonth], obj);
      saveData.year = objYear;
    }
    return saveData;
    
  }

  save(rawData) {
    let id = rawData._id;
    let data = rawData;
    this.props.actions.save(id, data, this.props.cas.key);
  }

  saveGoal(rowData) {
    let rowFound = false;
    const goalData = [];
    this.state.goalTabData.forEach((item) => {
      if(item.year === rowData.year) {
        goalData.push(rowData);
        rowFound = true;
      } else {
        goalData.push(item);
      }
    });
    if(!rowFound) goalData.push(rowData);
    this.setState({goalTabData: goalData});
    
    let obj = {
      normalizedDomesticWaterUsage: rowData.normalizedDomesticWaterUsage,
      normalizedUtilityWaterUsage: rowData.normalizedUtilityWaterUsage
    };
    let objYear = rowData.year;

    // Use the callback to rebuild the original format to push to the database
    this.save(this.rebuildGoal(obj, objYear));
  }

  saveUsage(rowData) {
    let rowFound = false;
    const usageData = [];
    this.state.usageTabData.forEach((item) => {
      if(item.year === rowData.year && item.month === rowData.month) {
        usageData.push(rowData);
        rowFound = true;
      } else {
        usageData.push(item);
      }
    });
    if(!rowFound) usageData.push(rowData);
    let obj = {
      domestic: rowData.domestic,
      agriculture: rowData.agriculture,
      fisheries: rowData.fisheries,
      utility: rowData.utility
    };

    let objMonth = rowData.month;
    let objYear = rowData.year;
    this.setState({usageTabData: usageData});
    this.save(this.rebuildUsage(obj, objYear, objMonth));
  }

  formatData(rawData){
    // build an array for the table
    // index 0 = goal, index 1 = monthly usage, index 3 = metric
    const formatted = []
    formatted[0] = rawData.map(item => ({
      normalizedDomesticWaterUsage: item.usage.normalizedDomesticWaterUsage,  
      normalizedUtilityWaterUsage: item.usage.normalizedUtilityWaterUsage,
      year: item.year}));
    let arr = []
    for (let i = 0; i < Object.keys(rawData).length; i++) {
      // check if monthly usage is empty
      if (Object.keys(rawData[i].monthlyUsage).length === 0) {
        continue
      }
      var element = {}
      element = Object.keys(rawData[i].monthlyUsage).map((month) => ({
        month: month,
        year: rawData[i].year,
        ...rawData[i].monthlyUsage[month]
      }));
      arr.push(element)
    }

    const monthlyUsageItem = [].concat(...arr)
    formatted[1] = monthlyUsageItem.map(item => ({
      month: item.month,
      domestic: item.domestic,
      utility: item.utility,
      agriculture: item.agriculture,
      fisheries: item.fisheries,
      year: item.year}));

    // Sorting the list of objects based on the year
    formatted[0].sort((a, b) => a.year - b.year);
    formatted[1].sort((a, b) => a.year - b.year);
    formatted[2] = []
    return formatted;
  }

  componentDidUpdate(prevProp){
    if (prevProp.data !== this.props.data){
      const formattedData = this.formatData(this.props.data);
      this.setState({goalTabData:formattedData[0],
      usageTabData: formattedData[1]});
    }
  }

  render() {
    if (this.state.goalTabData.length === 0) {
      return null;
    }
    return (
      <div className="water-dashboard">
        <div className="general-wrapper">
          <div className="general-card">
          
            <TabbedNavigation
              titles={TABS_DATA.map(d => d.title)}
              renderView={index => (
                <Table
                  data={index === 0 ? this.state.goalTabData : this.state.usageTabData}
                  options={WATER_DATA_OPTIONS.filter(
                    d => TABS_DATA[index].ids.indexOf(d.id) !== -1
                  ).sort(
                    (a, b) =>
                      TABS_DATA[index].ids.indexOf(a.id) -
                      TABS_DATA[index].ids.indexOf(b.id)
                  )}
                  onRowAdd={index === 0 ? this.saveGoal : this.saveUsage}
                  onRowEdit={index === 0 ? this.saveGoal : this.saveUsage}
                />
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ConnectCollection("waterDashboard")(Water);
