import * as types from './actionTypes';
import axios from 'axios';

export function casLoginSuccess(cas) {
  return { type: types.CAS_LOGIN_SUCCESS, cas};
}

export function casLogoutSuccess() {
  return { type: types.CAS_LOGOUT_SUCCESS };
}

export function loginToCas(key) {
  return function(dispatch) {
    axios.get(process.env.PUBLIC_URL + '/auth/cas/confirm/'+key).then(cas => {
      dispatch(casLoginSuccess(cas.data));
    }).catch(error => {
      throw(error);
    });
  };
}

export function logoutOfCas(key) {
  return function(dispatch) {
    dispatch(casLogoutSuccess());
    axios.get(process.env.PUBLIC_URL + '/auth/cas/logout/'+key).then((redirect) => {
			window.location.href=redirect.data.logout;
    }).catch(error => {
      throw(error);
    });
  };
}
