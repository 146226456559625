import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function airtableReducer(state = initialState.records, action) {
  switch (action.type) {
    case types.LOAD_AIRTABLE_SUCCESS:
      return action.records

    default:
      return state;
  }
}
