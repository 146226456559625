import { COLLECTION_LOAD, COLLECTION_SAVE } from "../actions/actionTypes";
import initialState from "./initialState";

export default collectionName => {
  let LOAD = COLLECTION_LOAD(collectionName);
  let SAVE = COLLECTION_SAVE(collectionName);
  return (state = initialState[collectionName], action) => {
    switch (action.type) {
      case LOAD:
        return action.status === "success" ? action.data : [];

      case SAVE:
        if (action.status !== "success") {
          return state;
        }
        let newState = [...state];
        let index = newState.findIndex(d => d._id === action.data._id);
        if (index === -1) {
          newState.push(action.data);
        } else {
          newState[index] = action.data;
        }

        return newState;

      default:
        return state;
    }
  };
};
