import * as types from './actionTypes';
import axios from 'axios';
import { initializeJoulesTip, setObjectDefaults } from '../components/common/initialComponentState';

export function loadTipsListSuccess(tips) {
  return { type: types.LOAD_TIPS_LIST_SUCCESS, tips};
}

export function loadTipSuccess(tip) {
  return { type: types.LOAD_TIP_SUCCESS, tip};
}

export function createTipSuccess(tip) {
  return {type: types.CREATE_TIP_SUCCESS, tip};
}

export function updateTipSuccess(tip) {
  return {type: types.UPDATE_TIP_SUCCESS, tip};
}

export function loadTipsList() {
  return function(dispatch) {
    axios.get(process.env.PUBLIC_URL + '/api/tip').then(tips => {
      dispatch(loadTipsListSuccess(tips.data));
    }).catch(error => {
      throw(error);
    });
  };
}

export function loadTip(tipId) {
  return function(dispatch) {
	  if(tipId === "new")
		  dispatch(loadTipSuccess(initializeJoulesTip));
	  else{
		let tipUrl = process.env.PUBLIC_URL + '/api/tip/' + tipId;
		axios.get(tipUrl).then(tip => {
		  dispatch(loadTipSuccess(setObjectDefaults(initializeJoulesTip, tip.data[0])));
		}).catch(error => {
		  throw(error);
		});
	  }
  };
}


export function saveTip(tipId, tipState, key, history) {
  return function (dispatch, getState) {
	  let tipUrl = process.env.PUBLIC_URL + '/api/tip/' + tipId + '/' + key;
	  axios.post(tipUrl, tipState)
		  .then(response => {
		  if(response.status === 200)
			  history.push("/tips");
	  }).catch(error => {
      throw(error);
    });
  };
}
