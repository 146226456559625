import React from 'react';
import PropTypes from 'prop-types';
import * as Inputs from '../common/Inputs';
import * as utils from '../common/utilities';
import { joulesTipEntry } from '../common/entryTypes';

const TipInformation = ({tip, onChange, errors}) => {
	return (
		<div className="tip-body">
		{joulesTipEntry.map((entry, index) => {
						let	TagName = Inputs[entry.type];
						if(entry.fullWidth)
							return(
								<div key={index} className={entry.class}>
									<div className="full-text-input">
										<TagName
											name={entry.key}
											label={entry.label}
											error={errors[entry.key]}
											value={utils.getNestedValue(tip, entry.key)}
											onChange={onChange}
										/>
									</div>
								</div>
							);
						return(
							<div key={index} className={entry.class}>
								<TagName
									name={entry.key}
									label={entry.label}
									error={errors[entry.errorKey || entry.key]}
									value={utils.getNestedValue(tip, entry.key)}
									onChange={onChange}
									options={entry.options}
									step={entry.step}
									min={entry.min}
									max={entry.max}
								/>
							</div>
							);
						})
			}
		</div>
	);
};

TipInformation.propTypes = {
	tip: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired
}

export default TipInformation;