import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as casActions from '../actions/casActions';
import PropTypes from 'prop-types';
import Header from "./header/Header";
import Navigation from "./navigation/Navigation";

class Main extends React.Component {
	componentDidMount() {
		let query = window.location.search;
		if(query !== "" && query.indexOf("key=") > -1) {
			let key = query.slice(query.indexOf("key=")+4);
			if(key.indexOf("&") > -1)
				key = key.slice(0, key.indexOf("&"));
			localStorage.setItem("key", key);
			this.props.actions.loginToCas(key);
		}
		else{
			let oldKey = localStorage.getItem("key") || "temp";
			this.props.actions.loginToCas(oldKey);
		}
	}
	
	componentDidUpdate(prevProps) {
		if(JSON.stringify(this.props.cas) !== JSON.stringify(prevProps.cas))
			if(!this.props.cas.authenticated)
				window.location.href = '/auth/cas';
	}
	
  render() {
		if(this.props.cas.authenticated)
			return (
      	<React.Fragment>
				 	<Header />
          <Navigation />
				</React.Fragment>
    );
		else
			return null;
  }
}

Main.propTypes = {
	cas: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		cas: state.cas
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(casActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);