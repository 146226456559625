import BuildingListPage from '../buildings/buildingListPage/BuildingListPage';
import BuildingPage from '../buildings/BuildingPage.js';
import TipListPage from '../tips/tipListPage/TipListPage';
import TipPage from '../tips/TipPage';
import ImportPage from '../button/ImportPage';
import GeneralPage from '../general/GeneralPage';
import EnergyStory from '../energyStory/EnergyStory';
import Water from '../water/Water';
import './Navigation.css';

import React from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';

const routes = [
  {
    path: process.env.PUBLIC_URL + "/",
    exact: true,
    component: BuildingListPage
  },
  {
    path: process.env.PUBLIC_URL + "/building/:buildingId",
    exact: true,
    component: BuildingPage
  },
  { path: process.env.PUBLIC_URL + "/tips", component: TipListPage },
  {
    path: process.env.PUBLIC_URL + "/tip/:tipId",
    exact: true,
    component: TipPage
  },
  { path: process.env.PUBLIC_URL + "/general", component: GeneralPage },
  { path: process.env.PUBLIC_URL + "/energyStory", component: EnergyStory },
  { path: process.env.PUBLIC_URL + "/water", component: Water },
  { path: process.env.PUBLIC_URL+'/import',
		component: ImportPage
 }
];

class Navigation extends React.Component {
	render() {
		return (
      <Router basename={`${process.env.PUBLIC_URL}`} forceRefresh={true}>
        <div className="navigation-bar">
          <ul>
            <Link to={`${process.env.PUBLIC_URL}/`}>
              <li>Buildings</li>
            </Link>
            <Link to={`${process.env.PUBLIC_URL}/general`}>
              <li>General</li>
            </Link>
            <Link to={`${process.env.PUBLIC_URL}/energyStory`}>
              <li>Energy Story</li>
            </Link>
            <Link to={`${process.env.PUBLIC_URL}/water`}>
              <li>Water</li>
            </Link>
            <Link to={`${process.env.PUBLIC_URL}/import`}>
              <li>Import</li>
            </Link>
          </ul>
          <Switch>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            ))}
          </Switch>
        </div>
      </Router>
    );
	}
}

export default Navigation;
