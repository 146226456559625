const booleanTypes = [
	{
		"text": "True",
		"value": true
	},
	{
		"text": "False",
		"value": false
	}
];

const buildingTypes = [
	{
		"text": "Classroom",
		"value": "classroom"
	},
	{
		"text": "Community",
		"value": "community"
	},
	{
		"text": "Housing",
		"value": "housing"
	},
	{
		"text": "Lab",
		"value": "lab"
	},
	{
		"text": "Office",
		"value": "office"
	}
];

export const certificationTypes = [
	{
		"text": "LEED",
		"value": "leed"
	},
	{
		"text": "BREEAM",
		"value": "breeam"
	}
];

export const commodityTypes = [
	{
		"text": "Chilled Water",
		"value": "chilledWater"
	},
	{
		"text": "Electricity",
		"value": "electricity"
	},
	{
		"text": "Natural Gas",
		"value": "gas"
	},
	{
		"text": "Solar",
		"value": "solar"
	},
	{
		"text": "Steam",
		"value": "steam"
	}
];

export const filterTypes = [
	{
		"text": "Active",
		"value": 'active',
		"type": 'Select',
		"options": booleanTypes,
		"verb": "is"
	},
	{
		"text": "Building Type",
		"value": 'buildingType',
		"type": 'Select',
		"options": buildingTypes,
		"verb": "is"
	},
	{
		"text": "Certification",
		"value": 'certifications',
		"type": 'Select',
		"options": certificationTypes,
		"verb": "includes"
	},
	{
		"text": "Commodity",
		"value": 'energyInfo.commodities',
		"type": 'Select',
		"options": commodityTypes,
		"verb": "includes"
	},
	{
		"text": "EUI Coming Soon",
		"value": 'euiComingSoon',
		"type": 'Select',
		"options": booleanTypes,
		"verb": "is"
	},
	{
		"text": "EUI Ready",
		"value": 'euiReady',
		"type": 'Select',
		"options": booleanTypes,
		"verb": "is"
	}
]

export const leedTypes = [
	{
		"text": "Certified",
		"value": ""
	},
	{
		"text": "Silver",
		"value": "silver"
	},
	{
		"text": "Gold",
		"value": "gold"
	},
	{
		"text": "Platinum",
		"value": "platinum"
	}
];

export const joulesTipEntry = [
	{ key: 'active',
		label: "Active",
		type: "Checkbox",
		class: "tip-active"
	},
		{ key: 'visibilityDelay',
		label: "Display Start in seconds",
		type: "Number",
		class: "tip-display-start",
		step: 0.1,
		min: 0,
		max: 120
	},
	{ key: 'headline',
		label: "Headline",
		type: "Text",
		class: "tip-headline",
	 	fullWidth: true
	},
	{ key: 'body',
		label: "Main Content",
		type: "Text",
		class: "tip-body-content",
	 	fullWidth: true
	},
	{ key: 'link',
		label: "URL Link",
		type: "Text",
		class: "tip-link",
	 	fullWidth: true
	},
	{ key: 'center.lat',
		label: "Latitude",
		type: "Number",
		class: "information-latitude",
	 	errorKey: "lat",
		step: 0.00001,
		min: -90,
		max: 90
	},
	{ key: 'center.long',
		label: "Longitude",
		type: "Number",
		class: "information-longitude",
	 	errorKey: "long",
		step: 0.00001,
		min: -180,
		max: 180
	}
];

export const buildingInformationEntry = [
	{ key: 'active',
		label: "Active",
		type: "Checkbox",
		class: "information-active"
	},
	{
		key: 'energyInfo.hotWaterConversion',
		label: 'Hot Water Conversion',
		type: 'Date',
		class: 'hot-water-conversion-date',
		min: "2019-01",
		max: new Date().toISOString().split("T")[0].slice(0,-3)
	},
	{ key: 'euiReady',
		label: "EUI Ready",
		type: "Checkbox",
		class: "information-eui-ready"
	},
	{ key: 'euiComingSoon',
		label: "EUI Coming Soon",
		type: "Checkbox",
		class: "information-eui-coming-soon"
	},
	{ key: 'name',
		label: "Building Name",
		type: "Text",
		class: "information-display-name",
	 	fullWidth: true
	},
	{ key: '_id',
		label: "Short Name",
		type: "Text",
		class: "information-short-name"
	},
	{ key: 'buildingType',
		label: "Building Type",
		type: "Select",
		class: "information-building-type",
		options: buildingTypes
	},
	{ key: 'primaryUse',
		label: "Primary Use",
		type: "Text",
		class: "information-primary-use"
	},
	{ key: 'primaryPercent',
		label: "Primary Percentage",
		type: "Number",
		class: "information-primary-percent",
		step: 0.01,
		min: 0,
		max: 1
	},
	{ key: 'secondaryUse',
		label: "Secondary Use",
		type: "Text",
		class: "information-secondary-use"
	},
	{ key: 'secondaryPercent',
		label: "Secondary Percentage",
		type: "Number",
		class: "information-secondary-percent",
		step: 0.01,
		min: 0,
		max: 1
	},
	{ key: 'center.lat',
		label: "Latitude",
		type: "Number",
		class: "information-latitude",
	 	errorKey: "lat",
		step: 0.00001,
		min: -90,
		max: 90
	},
	{ key: 'center.long',
		label: "Longitude",
		type: "Number",
		class: "information-longitude",
	 	errorKey: "long",
		step: 0.00001,
		min: -180,
		max: 180
	}
];

export const buildingCommodityEntry = {
	annualUsage: [
		{ key: 'energyInfo.tags.annualUsage.chilledWater',
			label: "Chilled Water",
			type: "Checkbox",
		  class: "commodity-option-chilled-water"
		},
		{ key: 'energyInfo.tags.annualUsage.electricity',
			label: "Electricity",
			type: "Checkbox",
		  class: "commodity-option-electricity"
		},
		{ key: 'energyInfo.tags.annualUsage.gas',
			label: "Natural Gas",
			type: "Checkbox",
		  class: "commodity-option-gas"
		},
		{ key: 'energyInfo.tags.annualUsage.solar',
			label: "Solar",
			type: "Checkbox",
		  class: "commodity-option-solar"
		},
		{ key: 'energyInfo.tags.annualUsage.steam',
			label: "Steam/Hot Water",
			type: "Checkbox",
		  class: "commodity-option-steam"
		}
	],
	monthlyUsage: [
		{ key: 'energyInfo.tags.monthlyUsage.chilledWater',
			label: "Chilled Water",
			type: "Checkbox",
		  class: "commodity-option-chilled-water"
		},
		{ key: 'energyInfo.tags.monthlyUsage.electricity',
			label: "Electricity",
			type: "Checkbox",
		  class: "commodity-option-electricity"
		},
		{ key: 'energyInfo.tags.monthlyUsage.gas',
			label: "Natural Gas",
			type: "Checkbox",
		  class: "commodity-option-gas"
		},
		{ key: 'energyInfo.tags.monthlyUsage.solar',
			label: "Solar",
			type: "Checkbox",
		  class: "commodity-option-solar"
		},
		{ key: 'energyInfo.tags.monthlyUsage.steam',
			label: "Steam/Hot Water",
			type: "Checkbox",
		  class: "commodity-option-steam"
		}
	],
	demand: [
		{ key: 'energyInfo.tags.demand.chilledWater',
			label: "Chilled Water",
			type: "Checkbox",
		  class: "commodity-option-chilled-water"
		},
		{ key: 'energyInfo.tags.demand.electricity',
			label: "Electricity",
			type: "Checkbox",
		  class: "commodity-option-electricity"
		},
		{ key: 'energyInfo.tags.demand.gas',
			label: "Natural Gas",
			type: "Checkbox",
		  class: "commodity-option-gas"
		},
		{ key: 'energyInfo.tags.demand.solar',
			label: "Solar",
			type: "Checkbox",
		  class: "commodity-option-solar"
		},
		{ key: 'energyInfo.tags.demand.steam',
			label: "Steam/Hot Water",
			type: "Checkbox",
		  class: "commodity-option-steam"
		}
	],
	eui: [
		{ key: 'energyInfo.tags.euis.chilledWater',
			label: "Chilled Water",
			type: "Checkbox",
		  class: "eui-option-chilled-water"
		},
		{ key: 'energyInfo.tags.euis.electricity',
			label: "Electricity",
			type: "Checkbox",
		  class: "eui-option-electricity"
		},
		{ key: 'energyInfo.tags.euis.gas',
			label: "Natural Gas",
			type: "Checkbox",
		  class: "eui-option-gas"
		},
		{ key: 'energyInfo.tags.euis.solar',
			label: "Solar",
			type: "Checkbox",
		  class: "eui-option-solar"
		},
		{ key: 'energyInfo.tags.euis.steam',
			label: "Steam/Hot Water",
			type: "Checkbox",
		  class: "eui-option-steam"
		},
		{ key: 'energyInfo.tags.euis.total',
			label: "Total",
			type: "Checkbox",
		  class: "eui-option-Total"
		}
	],
	euiReady: [
		{ value: "Unavailable",
			text: "Unavailable"
		},
		{ value: "euiComingSoon",
			text: "Coming Soon"
		},
		{ value: "euiReady",
			text: "Ready"
		}
	]
}
