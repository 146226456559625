import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { buildingCommodityEntry } from '../../../common/entryTypes';
import { Checkbox } from '../../../common/Inputs';
import { commodityObject } from '../../../common/initialComponentState';
import * as utils from '../../../common/utilities';
import { initializeBuilding } from '../../../common/initialComponentState';
import check from './images/pi-check.svg';
import error from './images/pi-exclamation.svg';

import Unmetered from './Unmetered';

const CommodityItem = ({entry, building, onChange}) => {
	let tagStatus = entry.key + '.tagStatus',
			active = entry.key + '.active';
	return(
		<div className={"commodity-list-option " + entry.class}>
			<div className="commodities-status">{getIcon(utils.getNestedValue(building, tagStatus))}</div>
			<Checkbox
				name={active}
				label={entry.label}
				value={utils.getNestedValue(building, active) || utils.getNestedValue(initializeBuilding, active)}
				id="commodity"
				onChange={onChange}/>
		</div>
		);
};

const getIcon = (status) => {
	if(status === "")
		return;
	else if(status === "Pi tag and data are good.")
		return (<img src={check} alt={status} />);
	else
		return (<img src={error} alt={status} />);
};

class CommoditiesTab extends React.Component {
	constructor(props) {
		super(props);
		this.buildState = this.buildState.bind(this);
		this.validateAllPiWebIds = this.validateAllPiWebIds.bind(this);
	}

	buildState(field, value, validate) {
		field = field.slice(0, field.indexOf(".active"));
		value = this.buildCommodityValue(field, value, validate);
		value = utils.parseEventObjects(field, value, this.props.building);
		field = field.slice(0, field.indexOf("."));
		this.props.updateCommodity(field, value);
	}

	buildCommodityValue(field, value, validate) {
			let newValue = Object.assign({}, commodityObject);
			newValue.active = value;
		if(value || validate) {
			newValue.tagName = this.buildTagName(field);
			this.getPiWebId(newValue.tagName, field);
		}
		return newValue;
	}

	buildTagName(field) {
		let fieldVariables = field.split("."),
			tag = "";
		if((fieldVariables[3] !== "solar") && (fieldVariables[2] !== "euis")) {
			tag = this.props.building._id + "_" + fieldVariables[3] + "_" + fieldVariables[2];
		}
		else{
			if ((fieldVariables[3] === "solar")){
			tag = "mainstpower." + this.props.building._id + "_" + fieldVariables[2];
		} else if (fieldVariables[2] === "euis") {
			if(fieldVariables[3] === "total")
				tag = this.props.building._id + "_eui";
			else
				tag = this.props.building._id + "_" + fieldVariables[3] + "_eui";
		}
		}
		if (fieldVariables[2] === "demand" || fieldVariables[3] === "solar")
			tag += "_kbtu";
		return tag;
	}

  getPiWebId(tagName, field) {
		let targetProperty = field + ".webId";
		axios.get('/api/pi/tag/' + tagName).then(webId => {
			this.validatePiWebId(webId.data, field);
			field = field.slice(0, field.indexOf("."));
			this.props.updateCommodity(field, utils.parseEventObjects(targetProperty, webId.data, this.props.building));
		}).catch(error => {
			throw (error);
		});
	}

	validateAllPiWebIds() {
		for(let i in buildingCommodityEntry) {
			for(let j=0; j<buildingCommodityEntry[i].length; j++) {
				let commodity = buildingCommodityEntry[i][j],
						active = commodity.key + ".active",
						value = utils.getNestedValue(this.props.building, active);
				this.buildState(active, value, true);
			}
		}
	}

	validatePiWebId(webId, field) {
		let building = this.props.building;
		if (webId === "Invalid tag name.") {
			let invalidValue = Object.assign({}, commodityObject);
			invalidValue.tagStatus = "Pi tag does not exist.";
			invalidValue = utils.parseEventObjects(field, invalidValue, building);
			field = field.slice(0, field.indexOf("."));
			this.props.updateCommodity(field, invalidValue);
		} else {
			let valiatedTimestamp = new Date(),
				timestampProperty = field + ".validatedOn",
				tagStatusProperty = field + ".tagStatus";
			field = field.slice(0, field.indexOf("."));
			this.props.updateCommodity(field, utils.parseEventObjects(timestampProperty, valiatedTimestamp, this.props.building));
			axios.get('/api/pi/webId/' + webId).then(validation => {
				this.props.updateCommodity(field, utils.parseEventObjects(tagStatusProperty, "Pi tag and data are good.", this.props.building));
			}).catch(error => {
				throw (error);
			});
		}
	}

	render() {
		return (
			<div id="commodities-content" className="building-tab-content">
				<div className="commodities-title">Annual Usage</div>
				<div className="commodities-list">
					{buildingCommodityEntry['annualUsage'].map((entry, index) => {
						return <CommodityItem key={index} entry={entry} building={this.props.building} onChange={e => this.buildState(e.target.name, e.target.checked)} />
						})
					}
				</div>
				<hr />
				<div className="commodities-title">Monthly Usage</div>
				<div className="commodities-list">
					{buildingCommodityEntry['monthlyUsage'].map((entry, index) => {
						return <CommodityItem key={index} entry={entry} building={this.props.building} onChange={e => this.buildState(e.target.name, e.target.checked)} />
						})
					}
				</div>
				<hr />
				<div className="commodities-title">Demand</div>
				<div className="commodities-list">
					{buildingCommodityEntry['demand'].map((entry, index) => {
						 return <CommodityItem key={index} entry={entry} building={this.props.building} onChange={e => this.buildState(e.target.name, e.target.checked)} />
						})
					}
				</div>
				<div className="commodities-validate-button" onClick={this.validateAllPiWebIds}>Validate Pi Tags</div>
				<hr />
				<Unmetered unmetered={this.props.building.energyInfo} updateArray={this.props.updateCommodity} />
			</div>
		);
	}
};

CommoditiesTab.propTypes = {
	building: PropTypes.object.isRequired,
	updateCommodity: PropTypes.func.isRequired
};

export default CommoditiesTab;
