import React from 'react';
import PropTypes from 'prop-types';
import InformationTab from './informationTab/InformationTab.js';
import CommoditiesTab from './commoditiesTab/CommoditiesTab.js';
import ImageTab from './ImageTab.js';
import EuiTab from './euiTab/EuiTab.js'
import './BuildingTabs.css';

const openTab = function(event) {
	event.preventDefault();
	var tabName = event.target.id+"-content";
    var i, tabcontent, tablinks;
	tabcontent = document.getElementsByClassName("building-tab-content");
    for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("building-tab-links");
    for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(tabName).style.display = "block";
    event.currentTarget.className += " active";
};

const BuildingTabs = ({building, onChange, errors, updateBuilding}) => {
  return (
    <div className="building-tabs-wrapper">
		<div className="building-tabs">
			  <button className="building-tab-links active" id="information" onClick={openTab}>Information</button>
			  <button className="building-tab-links" id="commodities" onClick={openTab}>Commodities</button>
				<button className="building-tab-links" id="eui" onClick={openTab}>EUIs</button>
		</div>
		<InformationTab building={building} onChange={onChange} errors={errors} updateArray={updateBuilding}/>
		<CommoditiesTab building={building} updateCommodity={updateBuilding} />
		<EuiTab building={building} updateCommodity={updateBuilding} />
		<ImageTab building={building} onChange={onChange} errors={errors}/>
    </div>
  );
};

BuildingTabs.propTypes = {
	building: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired
}

export default BuildingTabs;

//LINE 30:			  <button className="building-tab-links" id="image" onClick={openTab}>Image</button>
