import "./TabbedNavigation.css";
import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "../button/Button";

class TabbedNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 0
    };

    this.select = this.select.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.selected >= this.props.titles.length) {
      this.setState({ selected: 0 });
    }
  }

  select(index) {
    this.setState({
      selected: index
    });
  }

  render() {
    return (
      <>
        <div className="common-tabbed-navigation">
          <div className="common-tabbed-navigation-tabs">
            {this.props.titles.map((title, i) => (
              <Button
                key={i}
                selected={i === this.state.selected}
                onClick={_ => this.select(i)}
              >
                {title}
              </Button>
            ))}
          </div>

          <div className="common-tabbed-navigation-view">
            {this.props.renderView(this.state.selected)}
          </div>
        </div>
      </>
    );
  }
}

TabbedNavigation.propTypes = {
  titles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  renderView: PropTypes.func.isRequired // Call example: renderView(selectedIndex)
};

export default TabbedNavigation;
