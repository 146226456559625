import * as types from './actionTypes';
import {buildUri} from '../components/common/utilities';
import * as rp from 'request-promise';

export function loadAirtableSucess(records) {
  return { type: types.LOAD_AIRTABLE_SUCCESS, records};
}

export function loadAirtableData() {
  return function(dispatch) {
    const airtableImport = {
			method: 'GET',
			uri: buildUri('/api/airtable')
		};
    rp(airtableImport).then(results => {
		  dispatch(loadAirtableSucess(JSON.parse(results)));
		}).catch(error => {
		  throw(error);
		});
  }
}
