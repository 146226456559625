import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function tipsReducer(state = initialState.tips, action) {
  switch (action.type) {
    case types.LOAD_TIPS_LIST_SUCCESS:
      return action.tips;
		  
    default:
      return state;
  }
}
