import { campusEdges } from './initialComponentState';

export const buildingValidation = (buildingState, localErrors) => {
	let errors = Object.assign({}, localErrors);
	if(buildingState._id.length < 1)
		errors._id = 'Short name must be at least 1 character.';
	if(buildingState._id === "new")
		errors._id = 'Short name can not be set to new.';
	if(buildingState.name === "")
		errors.name = 'A Building Name is required.';
	if(buildingState.active){
		if(buildingState.buildingType==="")
			errors.buildingType = 'A Building Type is required.';
		
		if(buildingState.primaryPercent>0 && buildingState.primaryUse ==="")
			errors.primaryUse = 'A Primary Use is required.';
		
		if(buildingState.primaryPercent>0 && buildingState.primaryPercent>1)
			errors.primaryPercent = 'Percent must be in decimal format between 0 and 1.';
		
		if(buildingState.secondaryPercent>0 && buildingState.secondaryUse ==="")
			errors.secondaryUse = 'A Secondary Use is required.';
		
		if(buildingState.secondaryPercent<0 && buildingState.secondaryPercent>1)
			errors.secondaryPercent = 'Percent must be in decimal format between 0 and 1.';
		
		if(buildingState.primaryPercent+buildingState.secondaryPercent>1){
			errors.primaryPercent = 'Primary and Secondary Percentages may not equal more than 1.';
			errors.secondaryPercent = 'Primary and Secondary Percentages may not equal more than 1.';			
		}

		if(buildingState.center.lat < campusEdges.minimumLatitude || buildingState.center.lat > campusEdges.maximumLatitude)
			errors.lat = 'Campus latitude must be between 38.5 and 38.6';

		if(buildingState.center.long < campusEdges.minimumLongitude || buildingState.center.long > campusEdges.maximumLongitude)
			errors.long = 'Campus longitude must be between -122 and -121';
		
		if(buildingState.euiReady && buildingState.euiComingSoon){
			errors.euiReady = 'A building can not be EUI Ready and EUI Coming Soon';
			errors.euiComingSoon = errors.euiReady;
		}
	}
	return errors;
};


export const tipValidation = (tipState) => {
	let errors = {};
	if(tipState.active){
		if(tipState.headline==="")
			errors.headline = 'A Header is required.';
		
		if(tipState.body==="")
			errors.body = 'A Body is required.';
		
		if(tipState.center.lat < campusEdges.minimumLatitude || tipState.center.lat > campusEdges.maximumLatitude)
			errors.lat = 'Campus latitude must be between 38.5 and 38.6';

		if(tipState.center.long < campusEdges.minimumLongitude || tipState.center.long > campusEdges.maximumLongitude)
			errors.long = 'Campus longitude must be between -122 and -121';		
	}
	return errors;
};