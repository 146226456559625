import React from 'react';
import PropTypes from 'prop-types';
import * as Inputs from '../../../common/Inputs';
import * as utils from '../../../common/utilities';
import { buildingInformationEntry } from '../../../common/entryTypes';
import BuildingKeys from './BuildingKeys';
import Certifications from './Certifications';
import AliasNames from './AliasNames';

const InformationData = ({building, onChange, errors, updateArray}) => {
  return (
		<div id="information-content" className="building-tab-content">
			<div className="building-tab-information">
				{buildingInformationEntry.map((entry, index) => {
						let	TagName = Inputs[entry.type];
						if(entry.fullWidth)
							return(
								<div key={index} className={entry.class}>
									<div className="full-text-input">
										<TagName
											name={entry.key}
											label={entry.label}
											error={errors[entry.key]}
											value={utils.getNestedValue(building, entry.key)}
											onChange={onChange}
										/>
									</div>
								</div>
							);
						return(
							<div key={index} className={entry.class}>
								<TagName
									name={entry.key}
									label={entry.label}
									error={errors[entry.errorKey || entry.key]}
									value={utils.getNestedValue(building, entry.key)}
									onChange={onChange}
									options={entry.options}
									step={entry.step}
									min={entry.min}
									max={entry.max}
								/>
							</div>
							);
						})
				}
			</div>
			<hr />
			<BuildingKeys buildingKeys={building.bldgKey} errors={errors} updateArray={updateArray} />
			<hr />
			<Certifications certifications={building.certifications} errors={errors} updateArray={updateArray} />
			<hr />
			<AliasNames aliasNames={building.alias} errors={errors} updateArray={updateArray} />
		</div>
  );
};

InformationData.propTypes = {
	building: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired
}

export default InformationData;