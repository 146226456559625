import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as tipActions from '../../actions/tipActions';
import PropTypes from 'prop-types';
import { initializeJoulesTip } from '../common/initialComponentState';
import { parseEventObjects } from '../common/utilities';
import { tipValidation } from '../common/fieldValidation';
import TipInformation from './TipInformation.js';
import './TipPage.css';

class TipPage extends React.Component {
	
	constructor(props, context) {
		super(props, context);
		this.state = {
			tip: Object.assign({}, initializeJoulesTip),
			errors: {}
		};
		
		this.updateTipState = this.updateTipState.bind(this);
		this.saveTip = this.saveTip.bind(this);
	}
	
	componentDidMount() {
		this.props.actions.loadTip(this.props.match.params.tipId);
	}
			
	componentDidUpdate(prevProps, prevState) {
		if(JSON.stringify(this.props.tip) !== JSON.stringify(prevProps.tip))
			this.setState({
				tip: this.props.tip
			});
	}
	
	updateTipState(event) {
		let field = event.target.name;
		let tip = this.state.tip;
		let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		if(field.indexOf(".")>-1){
			value = parseEventObjects(field, value, tip);
			field = field.slice(0, field.indexOf("."));
		}
		tip[field] = value;
		return this.setState({tip: tip});
	}
		
	setNewState(field, value) {
		let tip = this.state.tip;
		tip[field] = value;
		this.setState({
			tip: tip
		});
	}

	saveTip() {
		if(this.tipFormIsValid())
			this.props.actions.saveTip(this.props.match.params.tipId, this.state.tip, this.props.cas.key, this.props.history);
	}
	
	tipFormIsValid() {
		let formIsValid = true,
			errors = Object.assign({}, tipValidation(this.state.tip));
		this.setState({errors: errors});
		if(Object.keys(errors).length !== 0)
			formIsValid = false;
		return formIsValid;
	}
	
	
	render() {
    return (
      <div className="tip-wrapper">
			<div className="tip-layout">
				<div className="tip-header">
					<div className="tip-title">{this.state.tip.headline}</div>
				<div className="tip-buttons">
					<Link to={`${process.env.PUBLIC_URL}/tips`} className="tip-cancel">Cancel
					</Link>
					<div onClick={this.saveTip} className="tip-save"> Save </div>
				</div> 
			</div>
				<TipInformation tip={this.state.tip} onChange={this.updateTipState} errors={this.state.errors}/>
			</div>
      </div>
    );
  }
}

TipPage.propTypes = {
	cas: PropTypes.object.isRequired,
	tip: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
		cas: state.cas,
    tip: state.tip
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(tipActions, dispatch)
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(TipPage);