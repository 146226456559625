import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '../../../common/Inputs';

const ALIAS_NAME_PROPERTY = "alias";

const addAliasName = (aliasNames, updateArray) => {
	let newKeys = aliasNames;
	newKeys.push("");
	updateArray(ALIAS_NAME_PROPERTY, newKeys);
};

const removeAliasName = (aliasNames, index, updateArray) => {
	let newKeys = [];
	for(let i=0; i<aliasNames.length; i++)
		if(i!==index)
			newKeys.push(aliasNames[i]);
	updateArray(ALIAS_NAME_PROPERTY, newKeys);	
}

const updateAliasNames = (aliasNames, updateArray) => {
	let buildNewArray = (event, location) => {
		if(event.target !== undefined){
			let newData = event.target.value,
					newArray = aliasNames;
			newArray[location] = newData;
			updateArray(ALIAS_NAME_PROPERTY, newArray);			
		}
	};
	return(
		<div className="building-tab-alias-name-list">
		{aliasNames.map((aliasName, index) => {
			return(
				<div className="building-tab-alias-name" key={"aliasName"+index}>
				<div className="building-tab-alias-name-remove" onClick={(e) => removeAliasName(aliasNames, index, updateArray)}>
					&times;
				</div>
				<div className="full-text-input">
				<Text
					name="aliasName"
					label="Alias Name"
					placeholder="ECO"
					value={aliasName}
					onChange={(e) => buildNewArray(e, index)} />
				</div>
				</div>
				);
			})
		}
		{aliasNames.length<1 && <div className="no-alias">No Alias Names to display.</div>}
		</div>
	);
}

const AliasNames = ({aliasNames, errors, updateArray}) => {
	return (
			<div className="building-tab-alias-names">
				<div className="building-tab-alias-names-header">
					<div className="building-tab-alias-names-title">
							Alias Names
						</div>
						<div className="building-tab-alias-names-add" onClick={(e) => addAliasName(aliasNames || [], updateArray)}>
								Add Alias Name
						</div>
				</div>
				{updateAliasNames(aliasNames || [], updateArray)}
			</div>
	);
};

AliasNames.propTypes = {
	aliasNames: PropTypes.array.isRequired,
	updateArray: PropTypes.func.isRequired
}

export default AliasNames;