import React from 'react';
import './Header.css';
import keysIcon from '../../images/keys-icon.svg';
import Help from './help/Help';
import Logout from './logout/Logout';

class Header extends React.Component {
	render() {
		return ( 
			<div className = "header-background">
				<div className = "header-wrapper">
					<img style={{height:'70px'}} src={keysIcon} alt="Administrator keys icon." />
					<div className = "header-title">CEED Administrator</div>
					<div className = "header-subtitle">Constructed: 2017</div>
				</div>
				<Help />
				<Logout />
			</div>
		);
	}
}

export default Header;
