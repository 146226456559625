import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function tipReducer(state = initialState.tip, action) {
  switch (action.type) {
	  case types.LOAD_TIP_SUCCESS:
		  return action.tip;

    case types.CREATE_TIP_SUCCESS:
      return [
        ...state,
        Object.assign({}, action.tip)
      ];

    case types.UPDATE_TIP_SUCCESS:
      return [
        ...state.filter(tip => tip.id !== action.tip.id),
        Object.assign({}, action.tip)
      ];

    default:
      return state;
  }
}
