import React from 'react';
import {Link} from 'react-router-dom';
import checkMark from '../../../images/check.svg';

const maxStringLength = 56;

const addSecondsString = (numericValue) => {
	if(numericValue !== undefined)
		return numericValue.toString() + " Seconds";
};

const convertBooleanToImage = (boolValue) => {
	if(boolValue)
		return <img src={checkMark} alt="True." />;
	return "";
};

const truncateString = (text) => {
	if(text.length > maxStringLength)
		return text.slice(0,maxStringLength) + "..."
	return text;
}

const TipListRow = ({tip}) => {
	let tipLink = process.env.PUBLIC_URL + "/tip/"+tip._id;
  return (
	  <Link to={tipLink}>
		<div className="tips-table-row">
		  <div className="tips-headline">{truncateString(tip.headline)}</div>
		  <div className="tips-body-message">{truncateString(tip.body)}</div>
		  <div className="tips-link">{truncateString(tip.link)}</div>
		  <div className="tips-display-start">{addSecondsString(tip.visibilityDelay)}</div>
		  <div className="tips-active tips-boolean-image">{convertBooleanToImage(tip.active)}</div>
		</div>
	  </Link>
  );
};

export default TipListRow;
