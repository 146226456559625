import React, {Component} from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import rootReducer from './reducers';
import thunk from 'redux-thunk';
import Main from './components/Main';
import { loadBuildingsList } from './actions/buildingActions';
import { loadTipsList } from './actions/tipActions';
import './App.css';
import './fonts/fonts.css';

let store = createStore(rootReducer, applyMiddleware(thunk));
store.dispatch(loadBuildingsList());
store.dispatch(loadTipsList());

class App extends Component {
	render() {
		return (
			<Provider store={store}>
				<Main />
			</Provider>
		);
	}
}

export default App;