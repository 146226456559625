export default {
  buildings: [],
  building: {},
	cas: {
		authenticated: null
	},
  tips: [],
  tip: {},
  records: {
    opportunities: "failed",
    hvac: "failed",
    holidays: "failed",
    aceFYsavings: "failed"
  },
  energyStoryData:[],
  waterDashboard:[]
};
