import React from 'react';
import PropTypes from 'prop-types';
import { Select } from '../../../common/Inputs';
import { commodityTypes } from '../../../common/entryTypes';

const ARRAY_NAME = 'unmeteredCommodities';
const UPDATE_PROPERTY = 'energyInfo';

const addUnmeteredCommodity = (unmetered, updateArray) => {
	let newUnmetered = Object.assign({}, unmetered);
	newUnmetered[ARRAY_NAME].push("");
	updateArray(UPDATE_PROPERTY, newUnmetered);
};

const buildNewArray = (event, location, updateArray, unmetered) => {
	if(event.target !== undefined){
		let newData = event.target.value,
				newUnmetered = Object.assign({}, unmetered);
		newUnmetered[ARRAY_NAME][location] = newData;
		updateArray(UPDATE_PROPERTY, newUnmetered);			
	}
};

const removeUnmeteredCommodity = (unmetered, index, updateArray) => {
	let newArray = [],
			newUnmetered = Object.assign({}, unmetered);
	for(let i=0; i<unmetered[ARRAY_NAME].length; i++)
		if(i!==index)
			newArray.push(unmetered[ARRAY_NAME][i]);
	newUnmetered[ARRAY_NAME] = newArray;
	updateArray(UPDATE_PROPERTY, newUnmetered);	
}

const updateUnmetered = (unmetered, updateArray) => {
	return(
		<div className="building-tab-unmetered-list">
		{unmetered[ARRAY_NAME].map((unmeteredItem, index) => {
			return(
				<div className="building-tab-unmetered" key={"unmetered"+index}>
				<div className="building-tab-unmetered-remove" onClick={(e) => removeUnmeteredCommodity(unmetered, index, updateArray)}>
					&times;
				</div>
				<Select 
					name="unmetered"
					label="Unmetered Commodity"
					options={commodityTypes}
					value={unmeteredItem}
					placeholder='Select Commodity'
					onChange={(e) => buildNewArray(e, index, updateArray, unmetered)}
					/>
				</div>
				);
			})
		}
		{unmetered.length<1 && <div className="no-keys">No Unmetered Commodities to display.</div>}		
		</div>
	);
}

const Unmetered = ({unmetered, errors, updateArray}) => {
	return (
			<div className="building-tab-unmetered">
				<div className="building-tab-unmetered-header">
					<div className="building-tab-unmetered-title">
							Unmetered Commodities
						</div>
						<div className="building-tab-unmetered-add" onClick={(e) => addUnmeteredCommodity(unmetered || [], updateArray)}>
								Add Unmetered
						</div>
				</div>
				{updateUnmetered(unmetered || [], updateArray)}
			</div>
	);
};

Unmetered.propTypes = {
	unmetered: PropTypes.object.isRequired,
	updateArray: PropTypes.func.isRequired
}

export default Unmetered;