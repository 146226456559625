import React, { Component } from "react";
import PropTypes from "prop-types";
import "./Table.css";
import * as DataTypes from "../dataTypes";

const Row = ({
  data,
  options,
  onEdit,
  editing,
  activeColumn,
  onColClicked,
  cancelEdit,
  saveEdit,
  errors
}) => (
  <tbody className={(onEdit ? "editable " : "") + (editing ? "editing" : "")}>
    <tr>
      {options.map((option, colIDX) => {
        let value = data[option.id];
        return (
          <td onClick={_ => onColClicked(colIDX)} key={option.id}>
            {editing ? (
              option.dataType.renderEditor(
                value,
                val => onEdit({ ...data, [option.id]: val }),
                {
                  className: errors[option.id] ? "invalid" : "",
                  ref: input =>
                    input && editing && activeColumn === colIDX && input.focus()
                }
              )
            ) : (
              <div>{option.dataType.renderValue(value)}</div>
            )}
          </td>
        );
      })}
    </tr>
    {editing && (
      <tr>
        <td colSpan="100%">
          <div className="common-table-button-cancel" onClick={cancelEdit}>
            Cancel
          </div>
          <div className="common-table-button-save" onClick={saveEdit}>
            Save
          </div>
        </td>
      </tr>
    )}
  </tbody>
);

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editingRow: null,
      activeCol: null, // use to set input to active
      editingData: {},
      editingErrors: []
    };

    this.rowClicked = this.rowClicked.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.saveEdit = this.saveEdit.bind(this);
    this.addNew = this.addNew.bind(this);
  }

  rowClicked(rowIDX, colIDX) {
    this.state.editingRow === null &&
      this.props.canEditRow &&
      this.props.canEditRow(rowIDX) &&
      this.setState({
        editingRow: rowIDX,
        activeCol: colIDX,
        editingData: this.props.data[rowIDX]
      });
  }

  cancelEdit() {
    this.setState({
      editingRow: null,
      activeCol: null,
      editingData: {},
      editingErrors: []
    });

    // Reset Data Types so that they can be reused!
    this.props.options.forEach(option => option.dataType.reset());
  }

  saveEdit() {
    // Validate Data
    let errors = {};
    let hasErrors = false;
    this.props.options.forEach(option => {
      errors[option.id] = !(!option.valid || option.valid(this.state.editingData[option.id]));
      hasErrors = hasErrors || errors[option.id];
    });
    if (hasErrors) {
      return this.setState({
        editingErrors: errors
      });
    }

    let onEditFunc =
      this.state.editingRow === this.props.data.length
        ? this.props.onRowAdd
        : this.props.onRowEdit;
    if (!onEditFunc) return;
    onEditFunc(this.state.editingData);
    this.cancelEdit(); //end Edit successfully
  }

  addNew() {
    let newData = {};
    this.props.options.forEach(option => {
      newData[option.id] = option.dataType.defaultValue;
    });

    this.setState({
      editingRow: this.props.data.length,
      activeCol: 0,
      editingData: newData
    });
  }

  render() {
    if (this.props.options.length === 0) return null;
    let renderRow = (dataPoint, rowIDX) => (
      <Row
        data={
          this.state.editingRow === rowIDX ? this.state.editingData : dataPoint
        }
        options={this.props.options}
        onEdit={
          this.props.canEditRow &&
          this.props.canEditRow &&
          this.props.canEditRow(rowIDX)
            ? value =>
                this.setState({
                  activeCol: null,
                  editingData: value
                })
            : null
        }
        editing={this.state.editingRow === rowIDX}
        activeColumn={
          this.state.editingRow === rowIDX ? this.state.activeCol : -1
        }
        onColClicked={colIDX => this.rowClicked(rowIDX, colIDX)}
        cancelEdit={this.cancelEdit}
        saveEdit={this.saveEdit}
        errors={this.state.editingErrors}
        key={rowIDX}
      />
    );

    return (
      <div className="common-table">
        <table
          className={
            this.state.editingRow !== null ? "common-table-editing-rows" : ""
          }
        >
          <thead>
            <tr>
              {this.props.options.map(value => (
                <th key={value.id}>{value.title}</th>
              ))}
            </tr>
          </thead>

          {/* Draw Data Rows */}
          {this.props.data.map(renderRow)}

          {/* The new row */}
          {this.state.editingRow === this.props.data.length &&
            renderRow(this.state.editingData, this.props.data.length)}

          {/* Add row button */}
          {this.props.onRowAdd &&
            this.state.editingRow !== this.props.data.length && (
              <tbody>
                <tr>
                  <td colSpan="100%">
                    <div
                      className="common-table-button-add"
                      onClick={this.addNew}
                    >
                      Add
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
        </table>
      </div>
    );
  }
}

Table.propTypes = {
  data: PropTypes.array.isRequired, // JSON OBJECT REPRESENTING THE DATA
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      valid: PropTypes.func, // Data Validator
      dataType: PropTypes.instanceOf(DataTypes.RootDataType)
    }).isRequired
  ).isRequired,

  // Called with the row's index if true is returned then the row is editable
  // else the row is not
  canEditRow: PropTypes.func.isRequired,

  // OPTIONAL: Called with an array of the new Row's data. You can return an
  // array of errors for validation errors.
  onRowAdd: PropTypes.func,

  // OPTIONAL: Called with an array of the Row's updated data. You can return an
  // array of errors for validation errors.
  onRowEdit: PropTypes.func
};

Table.defaultProps = {
  canEditRow: rowIndex => true
};

export default Table;
