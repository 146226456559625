import React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './ImportPage.css';
import * as airtableActions from '../../actions/airtableActions';
import * as utilities from '../common/utilities'


class ImportPage extends React.Component {
  constructor(props) {
		super(props);
    this.state = {
      opportunities : "failed",
      hvac: "failed",
      holiday: "failed",
      aceFYsavings: "failed",
      imported: false,
      loading : false

    }

    this.importOpportunities = this.importOpportunities.bind(this)
  };

  componentDidUpdate(prevProps) {
    if(this.props.records !== prevProps.records){
      this.setState({
        opportunities: this.props.records.opportunities,
        hvac: this.props.records.hvac,
        holiday: this.props.records.holiday,
        aceFYsavings: this.props.records.aceFYsavings,
        imported: true
      })
    }

  }

  importOpportunities() {
    this.setState({loading: true})
    this.props.actions.loadAirtableData()
  }

  render () {
    return(
      <div >
      <div className="import-tip">
        Press this button to import the Airtable Data from the Opportunities Table, Scheduling Opportunities Table, and Holiday Shutdowns Table. The data will then be placed in MongoDB.
        Please check success messages.
        </div>
      <div className="import-wrapper">
        {!(this.state.loading) && <div className={'import-button-'+this.state.imported} onClick= {e => this.importOpportunities()}> Import
      </div>}
      {(this.state.loading) && !(this.state.imported) && <div className={'import-button-'+this.state.loading} onClick= {e => this.importOpportunities()}> Loading...
    </div>}
    {(this.state.loading) && (this.state.imported) && <div className={'import-button-'+this.state.loading} onClick= {e => this.importOpportunities()}> Import Successful!
  </div>}
        {this.state.imported && <div className="import-list">
          <div>Opportunities Import: <div className={this.state.opportunities}>{utilities.capitalizeFirstLetter(this.state.opportunities)}</div></div>
      <div>HVAC Import: <div className ={this.state.hvac}>{utilities.capitalizeFirstLetter(this.state.hvac)}</div></div>
    <div>Holiday Import: <div className={this.state.holiday}>{utilities.capitalizeFirstLetter(this.state.holiday)}</div></div>
    <div>ACE Savings Import: <div className={this.state.aceFYsavings}>{utilities.capitalizeFirstLetter(this.state.aceFYsavings)}</div></div>
  </div>}
      </div>
      </div>

    )

  }
}

function mapStateToProps(state) {
	return {
    records: state.records
	};
}

function mapDispatchToProps(dispatch) {
	return {
    actions: bindActionCreators(airtableActions, dispatch)
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ImportPage));
