import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './TipListPage.css';
import TipListRow from './TipListRow';

class TipListPage extends React.Component {		
  render() {
    return (
      <div className="tips-wrapper">
			<div className="tips-table">
				<Link to={`${process.env.PUBLIC_URL}/tip/new`} className="new-tip-button">
					<div className="create-new-tip">Add Joules Tip</div>
				</Link>
				<div className="tips-header">
						<div className="tips-headline">Headline</div>
						<div className="tips-body-message">Body</div>
						<div className="tips-link">Link</div>
						<div className="tips-display-start">Display Start</div>
						<div className="tips-active">Active</div>
				</div>
				<div className="tips-body">
					{this.props.tips.length>0 && this.props.tips.map((tip) =>
						<TipListRow key={tip._id} tip={tip}/>
					)}
					{this.props.tips.length===0 && <div className="tips-none">No results found.</div>}
				</div>
			</div>
      </div>
    );
  }
}

TipListPage.propTypes = {
	tips: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  return {
    tips: state.tips
  };
}


export default connect(mapStateToProps)(TipListPage);